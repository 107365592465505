﻿/* CONTROL.IMAGE-GALLERY */

.c-image-gallery {
	.flickity-prev-next-button {
		top: calc(50% - 29px);
	}
}

.c-image-gallery__heading {
}

.c-image-gallery__slides {
	padding: 0;
	margin: 0;
	list-style: none;
}

.c-image-gallery__slide {
	width: 100%;

	&:nth-child(n+2) {
		display: none;

		.flickity-enabled & {
			display: block;
		}
	}
}

.c-image-gallery__caption {
	@include flex-extend;
	align-items: center;
	justify-content: flex-end;
	opacity: 0;

	.c-image-gallery__slide.is-selected & {
		opacity: 1;
	}
}

.c-image-gallery__caption-text {
	flex: 1 0 auto;
}

.c-image-gallery__caption-count {
	display: none;

	@include bp("medium") {
		display: inline;
		padding-left: $lsu;
		white-space: nowrap;
	}
}
