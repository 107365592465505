/* OBJECT.CONTROL */

.o-control {
    margin: $lsu 0;

    @include bp('large') {
        margin: $lsu*2 0;

        &.c-rte {
            margin: $lsu 0;
        }
    }

    .c-layout-aside & {
        @include bp('large') {
            margin: $lsu 0;
        }
    }
}

.o-control__heading {
	@include font-40-eb;
	position: relative;
	margin-bottom: $bsu;
	padding-top: $ssu*3;

	&--centred {
		text-align: center;
	}

	&:before {
		@include pseudo-element;
		top: 0;
		left: 0;
		width: 100px;
		height: 8px;
		background: $brand;
	}

	&--centred {
		&:before {
			left: 50%;
			transform: translateX(-50%);
		}
	}

	@include bp('large') {
		padding-top: $lsu;

		&:before {
			width: 110px;
			height: 10px;
		}
	}
}

.o-control__heading--small {
	@include font-30-eb;
	margin-bottom: $tsu*3;
}


.o-control__figcaption {
	@include ui-border(bottom);
	padding: $ssu 0;
}