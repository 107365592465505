﻿

.c-destination-finder {
	padding: $bsu 0;
	background: $blue--dark;
	color: $white;

	.selectric {
		background: none;
		overflow: hidden;
		white-space: nowrap;
		padding-top: $tsu;
		padding-bottom: $tsu;
		padding-right: 35px;
		cursor: pointer;
		border: none;
		border-bottom: 1px dotted $brand;
		min-width: 150px;
		margin-bottom: $ssu;
		/*line-height: 1.15;*/
		.label {
			@include font-30-eb;
			margin: 0;
			color: $brand;
			height: auto;
		}

		.button {
			display: none;
		}

		&:after {
			@extend %iconf;
			content: "\e911"; //down arrow
			position: absolute;
			right: 10px;
			top: 50%;
			transform: translateY(-50%);
			font-size: 14px;
			color: $brand;
			pointer-events: none;
		}

		@include bp('medium') {
			margin-bottom: 0;
		}

		@include bp('large') {
			min-width: 200px;
		}

		@include bp('extra-large') {
			min-width: 280px;
		}
	}

	.selectric-items {
		background: $grey--dark;
		border: none;
		width: 100%;
		padding-top: $ssu;
		padding-bottom: $ssu;

		li {
			@include font-20-eb;
			color: $white;
			padding: $tsu $bsu;

			&.highlighted, &.selected, &:hover {
				background: none;
				color: $brand;
			}
		}
	}
}

.c-destination-finder__title {
	@include font-30;
	text-align: center;
	margin-bottom: $bsu;

	@include bp("medium") {
		@include font-40;
	}
}

.c-destination-finder__left-content {
	@include bp('medium'){
		display: flex;
		justify-content: flex-end;
	}

}

.c-destination-finder__right-content {
	@include bp('medium') {
		display: flex;
	}
}

.c-destination-finder__text {
	@include font-30-eb;
	margin-right: $bsu;
	padding: $tsu 0;
}

.c-destination-finder__btn {
	padding: $ssu 3*$ssu;
	color: $brand;
	border-color: $brand;
	width: 100%;
	&:hover {
		color: $body-color;
	}
	@include bp('medium') {
		margin-left: $lsu;
		width: auto;
	}
}