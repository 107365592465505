/* UTILITY.ICONS */

// .iconf-* usage example
//-----------------------
/*
	<span class="iconf-facebook" aria-hidden="true"></span>
*/

@font-face {
	font-family: 'font-icons';
	src: 
		url("/content/fonts/font-icons/fonts/font-icons.woff2?tnfnl1") format("woff2"), 
		url("/content/fonts/font-icons/fonts/font-icons.ttf?tnfnl1") format("truetype"), 
		url("/content/fonts/font-icons/fonts/font-icons.woff?tnfnl1") format("woff"), 
		url("/content/fonts/font-icons/fonts/font-icons.svg?tnfnl1#font-icons") format("svg");
	font-weight: normal;
	font-style: normal;
}

// Chrome rendering bugfix - http://www.adtrak.co.uk/blog/font-face-chrome-rendering/
@media screen and (-webkit-min-device-pixel-ratio:0) {
	@font-face {
		font-family: 'font-icons';
		src: url('/content/fonts/font-icons/fonts/font-icons.svg#font-icons') format('svg');
	}
}

// Use !important to prevent issues with browser extensions that change fonts
[data-iconf]:before,
[class*="iconf-"],
%iconf {
	font-family: 'font-icons' !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	// Better Font Rendering
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

[data-iconf]:before {
	content: attr(data-iconf);
}

// Paste list of icon classes from Icomoon below this line

.iconf-award {
	&:before {
		content: $iconf-award;
	}
}

.iconf-calendar-tick {
	&:before {
		content: $iconf-calendar-tick;
	}
}

.iconf-food-service {
	&:before {
		content: $iconf-food-service;
	}
}

.iconf-footprints {
	&:before {
		content: $iconf-footprints;
	}
}

.iconf-handshake {
	&:before {
		content: $iconf-handshake;
	}
}

.iconf-head-brain {
	&:before {
		content: $iconf-head-brain;
	}
}

.iconf-location-pinpoint {
	&:before {
		content: $iconf-location-pinpoint;
	}
}

.iconf-small-group {
	&:before {
		content: $iconf-small-group;
	}
}

.iconf-star {
	&:before {
		content: $iconf-star;
	}
}

.iconf-sustainability {
	&:before {
		content: $iconf-sustainability;
	}
}

.iconf-thumbs-up {
	&:before {
		content: $iconf-thumbs-up;
	}
}

.iconf-value {
	&:before {
		content: $iconf-value;
	}
}

.iconf-compass {
	&:before {
		content: $iconf-compass;
	}
}

.iconf-baidu {
	&:before {
		content: $iconf-baidu;
	}
}

.iconf-coach {
	&:before {
		content: $iconf-coach;
	}
}

.iconf-swim {
	&:before {
		content: $iconf-swim;
	}
}

.iconf-swimming {
	&:before {
		content: $iconf-swimming;
	}
}

.iconf-walking {
	&:before {
		content: $iconf-walking;
	}
}

.iconf-cruise {
	&:before {
		content: $iconf-cruise;
	}
}

.iconf-location {
	&:before {
		content: $iconf-location;
	}
}

.iconf-filter {
	&:before {
		content: $iconf-filter;
	}
}

.iconf-cost {
	&:before {
		content: $iconf-cost;
	}
}

.iconf-dine {
	&:before {
		content: $iconf-dine;
	}
}

.iconf-duration {
	&:before {
		content: $iconf-duration;
	}
}

.iconf-fax {
	&:before {
		content: $iconf-fax;
	}
}

.iconf-kangaroo {
	&:before {
		content: $iconf-kangaroo;
	}
}

.iconf-kiosk {
	&:before {
		content: $iconf-kiosk;
	}
}

.iconf-kiwi {
	&:before {
		content: $iconf-kiwi;
	}
}

.iconf-language {
	&:before {
		content: $iconf-language;
	}
}

.iconf-quote {
	&:before {
		content: $iconf-quote;
	}
}

.iconf-sail {
	&:before {
		content: $iconf-sail;
	}
}

.iconf-weather {
	&:before {
		content: $iconf-weather;
	}
}

.iconf-wildlife {
	&:before {
		content: $iconf-wildlife;
	}
}

.iconf-chat {
	&:before {
		content: $iconf-chat;
	}
}

.iconf-wechat {
	&:before {
		content: $iconf-wechat;
	}
}

.iconf-google-plus {
	&:before {
		content: $iconf-google-plus;
	}
}

.iconf-instagram {
	&:before {
		content: $iconf-instagram;
	}
}

.iconf-arrow-down {
	&:before {
		content: $iconf-arrow-down;
	}
}

.iconf-arrow-up {
	&:before {
		content: $iconf-arrow-up;
	}
}

.iconf-arrow-left {
	&:before {
		content: $iconf-arrow-left;
	}
}

.iconf-arrow-right {
	&:before {
		content: $iconf-arrow-right;
	}
}

.iconf-close {
	&:before {
		content: $iconf-close;
	}
}

.iconf-minus {
	&:before {
		content: $iconf-minus;
	}
}

.iconf-plus {
	&:before {
		content: $iconf-plus;
	}
}

.iconf-tick {
	&:before {
		content: $iconf-tick;
	}
}

.iconf-download {
	&:before {
		content: $iconf-download;
	}
}

.iconf-external {
	&:before {
		content: $iconf-external;
	}
}

.iconf-link {
	&:before {
		content: $iconf-link;
	}
}

.iconf-photo {
	&:before {
		content: $iconf-photo;
	}
}

.iconf-camera {
	&:before {
		content: $iconf-camera;
	}
}

.iconf-video {
	&:before {
		content: $iconf-video;
	}
}

.iconf-play {
	&:before {
		content: $iconf-play;
	}
}

.iconf-email {
	&:before {
		content: $iconf-email;
	}
}

.iconf-google-maps {
	&:before {
		content: $iconf-google-maps;
	}
}

.iconf-address {
	&:before {
		content: $iconf-address;
	}
}

.iconf-calendar {
	&:before {
		content: $iconf-calendar;
	}
}

.iconf-home {
	&:before {
		content: $iconf-home;
	}
}

.iconf-search {
	&:before {
		content: $iconf-search;
	}
}

.iconf-phone {
	&:before {
		content: $iconf-phone;
	}
}

.iconf-share {
	&:before {
		content: $iconf-share;
	}
}

.iconf-tripadvisor {
	&:before {
		content: $iconf-tripadvisor;
	}
}

.iconf-tumblr {
	&:before {
		content: $iconf-tumblr;
	}
}

.iconf-twitter {
	&:before {
		content: $iconf-twitter;
	}
}

.iconf-flickr {
	&:before {
		content: $iconf-flickr;
	}
}

.iconf-pinterest {
	&:before {
		content: $iconf-pinterest;
	}
}

.iconf-facebook {
	&:before {
		content: $iconf-facebook;
	}
}

.iconf-linked {
	&:before {
		content: $iconf-linked;
	}
}

.iconf-youtube {
	&:before {
		content: $iconf-youtube;
	}
}

.iconf-blogger {
	&:before {
		content: $iconf-blogger;
	}
}

.iconf-snapchat {
	&:before {
		content: $iconf-snapchat;
	}
}

.iconf-weibo {
	&:before {
		content: $iconf-weibo;
	}
}

