﻿/* USP SECTION */
/* USP LIST */
/* USP LIST ITEM */


/* USP SECTION */

.c-usp {
	margin: 0;
	padding: 60px 0;

	&--light-grey {
		background: $grey--light-3;
	}

	&--white {
		background: $white;
		padding-bottom: 0 !important;
	}

	@include bp('large') {
		padding: 80px 0;
	}
}

.c-usp__intro {
	max-width: 1200px;
	margin: 0 auto 40px auto;
	text-align: center;

	@include bp('large') {
		margin-bottom: 60px;
	}
}

.c-usp__heading {
}

.c-usp__body {
	@include font-20;
}



/* USP LIST */

.c-usp__list {
	list-style: none;
	margin: 0;
	padding: 0;

	@include bp('large') {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
	}
}



/* USP LIST ITEM */
.c-usp-item {
	position: relative;
	margin-bottom: 24px;
	padding-left: 66px;

	&:last-of-type {
		margin-bottom: 0;
	}

	> * {
		margin-bottom: 10px;
	}

	@include bp('large') {
		flex-basis: calc((100% - 40px) /5);
		padding-left: 0;
		display: inline-flex;
		align-items: center;
		flex-direction: column;
		text-align: center;
		padding: 5px;
		& + & {
			margin-left: 10px;
		}

		> * {
			margin-bottom: 20px;
		}
	}
}

.c-usp-item__icon {
	position: absolute;
	left: 0;
	top: 0;
	width: 50px;
	height: 50px;
	background: $brand;
	color: $body-color;
	border-radius: 100%;
	font-size: 25px;
	display: flex;
	justify-content: center;
	align-items: center;

	@include bp('large') {
		position: static;
		width: 80px;
		height: 80px;
		font-size: 40px;
	}
}

.c-usp-item__title,
.c-usp-item__abstract {
	display: -webkit-box;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
}

.c-usp-item__title {
	$font-size: 18px;
	$dt-font-size: 20px;
	$line-height: 1.3;
	$line-clamp: 2;

	font-weight: $weight--extra-bold;
	-webkit-line-clamp: $line-clamp;
	font-size: $font-size;
	line-height: $line-height;

	@include bp('large') {
		font-size: $dt-font-size;
		height: $dt-font-size * $line-height * $line-clamp;
	}
}

.c-usp-item__abstract {
	$font-size: 16px;
	$dt-font-size: 18px;
	$line-height: 1.3;
	$line-clamp: 4;

	-webkit-line-clamp: $line-clamp;
	font-size: $font-size;
	line-height: $line-height;

	@include bp('large') {
		font-size: $dt-font-size;
		height: $dt-font-size * $line-height * $line-clamp;
	}
}

.c-usp-item__link {
	@include font-16-b;
	color: $body-color;
	position: relative;
	display: inline-block;

	&:after {
		display: block;
		content: '';
		height: 3px;
		width: 100%;
		left: 0;
		background: $brand;
	}
}
