/* OBJECT.FIGURE */

.o-figure {
	display: block;

	> picture {
		display: contents;
	}

	> img,
	> noscript > img,
	> picture > img {
		display: block;
		width: 100%;
	}
}
