/* CONTROL.LISTING-STACKED */

.c-listing-stacked {
	@include ui-border('top');
	margin: $bsu 0;
}

.c-listing-stacked__item {
	@include ui-border('bottom');
	margin-bottom: $ssu;

	&:last-child {
		margin: 0;
	}

	@include bp('medium') {
		margin-bottom: 0;
	}
}

.c-listing-stacked__item-heading {
	@include font-30-eb;
	margin-bottom: 0.3em;

	a {
		color: $body-color;

		&:hover {
			color: $brand;
		}
	}
}

.c-listing-stacked__item-meta {
	display: block;
	margin-bottom: 0.5em;
}

.c-listing-stacked__item-body {
	padding: $bsu 0;

	> *:last-child {
		margin-bottom: 0;
	}

	@include bp('large') {
		padding: $ssu*3 $lsu $bsu $bsu;
	}

	@include bp('extra-large') {
		padding: $lsu $lsu $bsu $bsu;
	}
}

.c-listing-stacked__item-text {
	@include font-20;
}