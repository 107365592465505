﻿.c-embed {
	form[action*="www.createsend.com"] {
		h1 {
			font-family: $base-font-family;
		}

		.o-btn--on-yellow { // DC
			background: $blue--dark;
			border-color: $blue--dark;

			&:hover,
			&:focus {
				background: $btn-purple;
				border-color: $btn-purple;
			}
		}
	}
}
