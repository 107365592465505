﻿/* COMPONENT.BREADCRUMB */

.c-breadcrumb {
	margin-bottom: $bsu;
	margin-top: 30px;

	li.invisible-on-mobile {
		display: none;
	}

	@include bp('large') {
		margin-bottom: 0;

		li.invisible-on-mobile {
			display: inline;
		}
	}
}

.c-breadcrumb__link {
	//@include font-16;
	color: $body-color;

	&:hover {
		text-decoration: underline;
	}
}
