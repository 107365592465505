﻿/* UTILITY.HELPERS */

// Visibility
// Hide only visually, but have it available for screenreaders
// More info: h5bp.com/v
.u-visuallyhidden {
	position: absolute;
	clip: rect(0 0 0 0);
	overflow: hidden;
	width: 1px;
	height: 1px;
	padding: 0;
	border: 0;
	margin: -1px;
}

// Extends the .visuallyhidden class to allow the element to be focusable when navigated to via the keyboard
// More info: h5bp.com/p
u-visuallyhidden.focusable:active,
.u-visuallyhidden.focusable:focus {
	position: static;
	clip: auto;
	overflow: visible;
	width: auto;
	height: auto;
	margin: 0;
}

// Hide from both screenreaders and browsers
// More info: h5bp.com/u
.u-hidden {
	display: none !important;
	visibility: hidden;
}

// Image replacement
// Text indent for IE6/7 fallback
.u-ir {
	background-color: transparent;
	border: 0;
	overflow: hidden;
	*text-indent: -9999px;

	&:before {
		content: "";
		display: block;
		width: 0;
		height: 100%;
	}
}

// Positioning
.u-abs { position: absolute !important; }
.u-rel { position: relative !important; }

// Absolutely position an element in the horizontal & vertical center of its container
.u-center {
	@extend .u-abs;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}

// Absolutely position an element in the horizontal center of its container
.u-center--h {
	@extend .u-abs;
	left: 50%;
	transform: translateX(-50%);
}

// Absolutely position an element in the vertical center of its container
.u-center--v {
	@extend .u-abs;
	top: 50%;
	transform: translateY(-50%);
}

// Display Helpers
.u-block { display: block !important; }
.u-inline { display: inline !important; }
.u-inline-b { display: inline-block !important; }

// Floats
.u-float--left { float: left !important; }
.u-float--right { float: right !important; }
.u-clear { clear: both !important; }
.u-clear--left { clear: left !important; }
.u-clear--right { clear: right !important; }

// Self clear
// Addition of .clearfix as some other plugins & Umbraco use this
.u-cf,
.clearfix { @include clearfix; }

// Text alignment
.u-text--left { text-align: left !important; }
.u-text--right { text-align: right !important; }
.u-text--center { text-align: center !important; }

// Typography
.u-weight--light { font-weight: $weight--light !important; }
.u-weight--normal { font-weight: $weight--normal !important; }
//.u-weight--semibold { font-weight: $weight--semibold !important; }
.u-weight--bold { font-weight: $weight--bold !important; }

.u-style--normal { font-style: normal !important; }
.u-style--italic { font-style: italic !important; }

.u-caps { text-transform: uppercase !important; }

// Javascript helpers
// Whole element is clickable so show correct cursor
.js-hot { cursor: pointer; }

// Hide when JS is enabled
.js-hidden {
	display: none;

	.no-js & {
		display: block;
	}
}

.js-lazy-auto,
.js-lazy-bg {

	.no-js & {
		display: none;
	}
}

//for dev remove later
.u-be-todo {
	background-color: rgba(green, 0.6);
	animation: blink-animation 1s steps(5, start) infinite;
	-webkit-animation: blink-animation 1s steps(5, start) infinite;
}

.u-fe-todo {
	background-color: rgba(orange, 0.6);
	animation: blink-animation 1s steps(5, start) infinite;
	-webkit-animation: blink-animation 1s steps(5, start) infinite;
}

@keyframes blink-animation {
	to {
		visibility: hidden;
	}
}

@-webkit-keyframes blink-animation {
	to {
		visibility: hidden;
	}
}