﻿/* CONTROL.SEARCH */

.c-global-search-field {
    display: flex;
    align-items: baseline;
    margin-bottom: 30px;
    flex-wrap: wrap;

    @include bp('large') {
        margin-bottom: 40px;
    }

    label {
        @include font-24-b;
        width: 100%;

        @include bp('small') {
            width: auto;
        }
    }

    input {
        @include font-20;
        margin-top: 10px;
        margin-left: 0;
        margin-right: $tsu;
        padding: 5px 10px;
        border: none;
        border-bottom: 1px solid $grey;
        border-radius: 0;
        color: $body-color;
        height: 45px;
        line-height: 45px;

        @include bp('small') {
            margin-left: $bsu;
            margin-right: $bsu;
        }
    }

    .o-search__btn {
        border: 1px solid $black;
        color: $black;
        padding: 12px;

        &:hover {
            background-color: $brand;
            border-color: $brand;
        }
    }
}

.c-search__summary {
    @include font-20;
}


.c-global-search__results .c-search-result {
    margin-bottom: $bsu;
    padding-bottom: $bsu;
    border-bottom: 1px solid $grey;
}

.c-global-search__results .c-search-result__heading {
    @include font-24-eb;
    margin-bottom: 10px;
    a {
        @include font-24-eb;
        color: $grey--dark;
    }
}


.c-global-search__results  .c-search-result__link {
    color: $blue;
}

.c-global-search__results .c-pagination {
    margin-top: $bsu;
    margin-bottom: $bsu;

    @include bp('medium') {
        margin-top: $lsu;
        margin-bottom: 0;
    }
}