﻿/* CONTROL.PODS */

.c-pods {
}

.c-pods__heading {
}

.c-pods__item {
}

// DEFAULT POD
.c-pod {
	&__ribbon {
	}
}

.c-pod__body {}

.c-pod__info {}

.c-pod__heading {
	.no-touchevents .c-pod:hover & {
		color: $white;
	}

	@include bp("large") {
		.c-pod:hover & {
			color: $white;
		}
	}
}

.c-pod__detail {
	a {
		margin: 0 10px 10px 0;

		&.o-btn-CTA {
			background: $white;
			color: $blue--dark;

			&:hover {
				background: $pink;
				color: $white;
			}
		}
	}
}

.c-pod__text {

}

.c-pod__meta {

}

.c-pod-bg {
}

// NO IMAGE POD
.c-pod-noimage {
}

.c-pod-noimage__body {
	a {
		margin: 0 10px 10px 0;

		&.o-btn-CTA {
			background: $white;
			color: $blue--dark;

			&:hover {
				background: $pink;
				color: $white;
			}
		}
	}


}

.c-pod-noimage__heading {
	margin-bottom: 10px;
}

// QUICKLINKS POD
.c-pod-quicklinks {
	height: 100%;
	background: $brand;
}

.c-pod-quicklinks__body {
}

.c-pod-quicklinks__heading {
}

.c-pod-quicklinks__item {
}

.c-pod-quicklinks__icon {
}

.c-pod-quicklinks__link {

}

//product pod
.c-product-pod {

}

.c-product-pod-wrapper {

}

.c-product-pod-bg {
}

.c-product-pod__body {
}

.c-product-pod__title {

}

.c-product-pod__location {
}

.c-product-pod__location__icon {
}

.c-product-pod__feature {
}

.c-product-pod__feature-item {
}

.c-product-pod__feature-item__icon {}

.c-product-pod__feature-item__text {}

.c-product-pod__feature-time {}

.c-product-pod__feature-from {}

.c-product-pod__feature-price {}

.c-product-pod-hover {



	a {
		margin-bottom: 10px;
	}
}

.c-product-pod-hover__title {
}

.c-product-pod-hover__location {
}

.c-product-pod-hover__abstract {

}

.c-product-pod__buttons {
	a {
		margin: 0 10px 10px 0;

		&.o-btn-CTA {
			background: $white;
			color: $blue--dark;

			&:hover {
				background: $pink;
				color: $white;
			}
		}
	}
}

// POD PROMO
.c-pod-promo__body {

}

.c-pod-promo__heading {
}

.c-pod-promo__text {
}

.c-pod-promo__btn {
}
