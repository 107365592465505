﻿/* CONTROL.HEADING */

.c-heading {
	margin: $lsu 0;
}

.c-heading__main {
	@include font-60-eb;
	position: relative;
	padding-top: $ssu*3;

	&:before {
		@include pseudo-element;
		top: 0;
		left: 0;
		width: 100px;
		height: 8px;
		background: $brand;
	}

	@include bp('medium') {
		padding-top: $lsu;

		&:before {
			width: 110px;
			height: 10px;
		}
	}
}

.c-heading__link {
    margin-top: $ssu*3;
    float: none;

    @include bp('medium') {
        margin-top: $lsu;
        float: right;
    }
}
