/* DOLPHINS.COMPONENT.NAVIGATION */

// Main Navigation
$navigation-bg: $white;

.c-navigation {
	background-color: white;

	@include bp('large') {
		background-color: transparent;
	}
}

.c-navigation__link {
	color: $blue--dark;

	@include bp('large') {
		color: $white;
		.is-sticky & {
			color: $blue--dark;
			background-color: $white;
		}

		.menu-expanded & {
			color: $blue--dark;
		}
	}
}
