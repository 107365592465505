/* COMPONENT.NAVIGATION */

// Main Navigation
$navigation-bg: $grey--dark;

.c-navigation {
	display: block;
	position: fixed;
	top: $header-mobile-height;
	left: 0;
	transform: translateX(100%);
	transition: all 0.5s ease;
	width: 100%;
	background-color: $navigation-bg;
	height: calc(100vh - #{$header-mobile-height});
	@include ui-border('top');

	&.is-expanded {
		top: $header-mobile-height;
		transform: translateX(0);
		overflow-y: auto;
	}

	@include bp('large') {
		display: block;
		background-color: inherit;
		position: static;
		transform: none;
		height: auto;
		border-top: none;

		&.is-sticky {
			transition: all 0.3s ease-in;
			position: fixed;
			width: 100%;
			left: 0;
			top: 0;
			background-color: $grey--dark;
			outline: 2px red solid;
		}
	}
}

.c-navigation__wrapper {

	@include bp('large') {
		max-width: rem($layout-wrapper-max-width);
		padding: 0 $bsu;
		margin: 0 auto;
	}
}

.c-navigation__language {
	padding: $ssu $bsu;

	@include bp('medium') {
		display: none;
	}
}

.c-navigation__items {
	padding-top: $tsu;

	@include bp('large') {
		width: 100%;
		display: table;
		padding: 0;
	}
}

.c-navigation__item {
	@include ui-border('bottom');
	position: relative;

	@include bp('large') {
		position: static;
		display: table-cell;
		width: 1%;
		border: none;
		text-align: center;
		vertical-align: bottom;
		&--one {
			position: relative;
		}
	}
}

.c-navigation__link {
	@include font-20-eb-1;
	display: block;
	padding: 15px 20px;
	color: $white;

	@include bp('large') {
		display: inline-block;
		padding: 25px 0;
		white-space: nowrap;
		position: relative;

		&:after {
			display: none;
			content: "";
			position: absolute;
			bottom: 0;
			left: 50%;
			transform: translateX(-50%);
			height: 4px;
			width: 70px;
			background: $brand;
		}

		&:hover {
			&:after {
				display: block;
			}
		}

		.is-sticky & {
			padding: 19px 0;
		}
	}

	&.overview_link {
		@include font-20-eb-megamenu;
		padding: $tsu*3 $lsu;

		&::after {
			display: none;
		}

		.icon {
			@include font-16;
		}

		@include bp('large') {
			display: none;
		}
	}
}

.c-navigation__link--home {
	font-size: rem(20px);

	@include bp('large') {
		line-height: 1;
	}
}

.c-navigation__expander {
	position: absolute;
	right: $bsu * 1.5;
	top: 16px;
	transform: rotate(0deg);
	transition: transform 0.2s ease-in;

	.c-navigation__item.is-expanded & {
		transform: rotate(90deg);
	}

	@include bp('large') {
		display: none;
	}
}

