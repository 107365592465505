﻿/* CONTROL.SUBNAV */

.c-subnav {
}

.c-subnav__wrapper {
    background-color: $grey--light
}

.c-subnav__heading {
    @include font-30-eb;
    display: block;
    background-color: $brand;
    padding: $bsu;
    color: $black;
    margin-bottom: 0.333em;
    text-transform: uppercase;
}

.c-subnav__items {
    padding: $bsu;
}

.c-subnav__item {
    margin-bottom: $ssu;
}

.c-subnav__item > a {
    @include font-20-eb;
    color: $blue;

    &:hover {
        color: $blue--dark;
    }

    &::before {
        @include font-12;
        font-family: 'font-icons' !important;
        content: "\e914";
        display: inline-block;
        margin-right: $ssu;
    }

    &.is-selected {
        color: $black;

        &::before {
            content: "\e911";
        }
    }
}

.c-subnav__link {

	&.is-selected {
		font-weight: $weight--bold;
	}
}

.c-subnav__children {
    padding-left: ($bsu + 10px);
    margin-top: $ssu;
    margin-bottom: $bsu;
}

.c-subnav__child{
    margin-bottom: $ssu;
}

.c-subnav__child-link {
    @include font-16;
    color: $blue;

    &:hover {
        color: $blue--dark;
    }
}