﻿/* COMPONENT.PAGINATION */

.c-pagination {
}

.c-pagination__number {
	@include font-18-eb;
	a {
		display: inline-block;
		padding: $ssu;
		margin: 0 $ssu;
		color: $blue;

		&:hover {
			text-decoration: underline;
		}
	}

	&.is-selected {

		a {
			text-decoration: underline;
			color: $body-color;
		}
	}
}

.c-pagination__next, .c-pagination__prev {
	a {
		position: relative;
		display: inline-block;
		width: 40px;
		height: 40px;
		margin: 0 $ssu;
		color: $body-color;
		background: $brand;
		vertical-align: middle;
		border: 2px solid $brand;

		&:hover {
			background: transparent !important;
		}
	}

	.c-pagination__icon {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
}

.c-pagination__mobile {
	font-size: 20px;

	a {
		color: $body-color;
	}
}

.c-pagination__mobile-dropdown {
	padding: $tsu $ssu*3 $tsu $ssu;
}
