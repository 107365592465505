﻿/* OBJECT.ROTATOR */

.o-rotator {
}

.o-rotator__viewport {
	position: relative;
	overflow: hidden;
	height: 0;
}

.o-rotator__items {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
}

.o-rotator__item {
	position: relative;
	width: 100%;
	height: 100%;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;
}