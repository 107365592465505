/* COMPONENT.FOOTER */

.c-footer {
	color: $white;
	background: $blue--dark;
	background: -webkit-gradient(linear, left top, right bottom, from(#0069b7), to($blue--dark));
	background: linear-gradient(to bottom right, #0069b7 0%, $blue--dark 100%);
}

.c-footer__link {
	color: $white;
}

.c-footer-social__link {
	color: $white;
	border: 1px solid $white;
	background: none;

	&:hover {
		background: $brand;
		border-color: $brand;
	}
}

.c-footer-image {
	width: 120px;

	@include bp('large') {
		width: 180px;
	}
}

.c-footer-back-to-top {
	margin-top: 30px;

	&:hover {
		color: $brand;
	}
}

.c-footer__cta {
	background: $blue--dark;
	color: $white;
	margin-bottom: $lsu;
}

.c-footer__cta-button {
	border: 1px solid $blue--dark;
	color: $white;
	background: $blue--bright;
}
