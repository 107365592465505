/* CONTROL.RTE */

.c-rte {
    overflow: hidden;
}

.mce-content-body,
.o-body-text {
	@include clearfix;
	@include font-20;

	& + & {
		margin-top: $bsu;
	}

	> *:last-child {
		margin-bottom: 0;
	}

	> *:first-child {
		margin-top: 0;
	}

	h2, h3, h4, h5, h6 {
		margin-bottom: 0.5em;
	}

	h2 {
		@include font-30-eb;
	}

	h3 {
		@include font-24-eb;
	}

	h4 {
		@include font-20-eb;
	}

	hr {
		border: 0;
		@include ui-border(top);
		height: 0;
		clear: both;
		margin: $bsu 0;
	}
	// Lists
	> ul,
	> ol {
		padding: 0 0 0 1em;
		margin: 1em 0 1em 3px;

		ul,
		ol {
			margin: 0.25em 0;
		}

		ul li {
			list-style-type: circle;
		}

		ol li {
			list-style-type: lower-roman;
		}
	}

	> ul > li {
		list-style-type: disc;
	}

	> ol > li {
		list-style-type: decimal;
	}
	// Tables
	table {
		width: 100%;
		min-width: 480px;
		clear: both;
		margin-bottom: $bsu;

		p {
			margin: 0;
		}

		&[style*="width"] {
			min-width: 0;
		}

		&[style*="float: left"] {
			margin-right: $bsu;
		}

		&[style*="float: right"] {
			margin-left: $bsu;
		}
	}

	th,
	thead td {
		font-weight: bold;
	}

	th,
	td {
		@include ui-border(all);
		padding: $ssu $bsu;
	}

	td {
		vertical-align: top;
	}

	caption {
		@include ui-border(bottom);
		caption-side: bottom;
		padding: $ssu 0;
		text-align: left;
	}
	a{
		color: $blue;
		&:hover{
			text-decoration: underline;
		}
	}
}

// RTE figure - shared styling across macros
.c-rte__figure {
	margin: $bsu auto;

	&.u-float--left {
		clear: left;

		@include bp('small') {
			margin-top: 0;
			margin-right: $bsu;
			margin-left: 0;
		}
	}

	&.u-float--right {
		clear: right;

		@include bp('small') {
			margin-top: 0;
			margin-right: 0;
			margin-left: $bsu;
		}
	}
}

.c-rte__figcaption {
	background-color: $grey--light;
	padding: $ssu $ssu*3;
}