﻿/* CONTROL.EXPAND-COLLAPSE */

.c-expand-collapse {
}

.c-expand-collapse__heading {
}

.c-expand-collapse__list {
	@include ui-border('top');
}

.c-expand-collapse__item {
	@include ui-border('bottom');
}

.c-expand-collapse__item-header {
	padding: $ssu;
	padding-left: $ssu * 5;
	cursor: pointer;

	&:hover,
	&:focus {
		outline: none;

		.c-expand-collapse__item-icon__field {
			background: darken($brand,10);
		}
	}

	@include bp('medium') {
		padding: $bsu;
		padding-left: $bsu * 4;
	}

	@include bp('large') {
		padding-left: $ssu * 9;
	}
}

.c-expand-collapse__item-heading {
	@include font-24-eb;
}
.c-expand-collapse__item-icon__field {
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	width: 40px;
	background: $brand;

	@include bp('medium') {
		width: 60px;
	}

	@include bp('large') {
		width: 70px;
	}
}

.c-expand-collapse__item-icon {
	&::before {
		content: $iconf-plus;
	}

	position: absolute;
	left: 50%;
	top: 50%;
	font-family: 'font-icons';
	font-size: 16px;
	transform: translate(-50%, -50%);
	transition: transform 0.3s linear;

	.c-expand-collapse__item.is-expanded &,
	.c-expand-collapse__item[aria-expanded="true"] & {
		&::before {
			content: $iconf-minus;
		}

		transform: translate(-50%, -50%) rotate(180deg);
	}

	@include bp('medium') {
		font-size: 20px;
	}
}

.c-expand-collapse__item-body {
	.js & {
		display: none;
	}

	padding: $bsu;

	.c-expand-collapse__item.is-expanded &,
	.c-expand-collapse__item[aria-expanded="true"] & {
		display: block;
	}

	@include bp('medium') {
		padding: $ssu * 3;
	}
}