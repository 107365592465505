@media (-webkit-min-device-pixel-ratio: 1.5) {
	.c-header {

		.c-header__logo {
			margin-right: 60px;
		}

		.c-navigation__wrapper {
			padding-left: 0;
			padding-right: 0;
			max-width: none;
		}

		.c-navigation__link {
			padding-left: 10px;
			padding-right: 10px;
		}
	}

	.c-hero-home__header {
		@include font-80-eb;
		transform: translateY(0);
		top: auto;
		bottom: $bsu;
	}
}