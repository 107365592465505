/* COMPONENT.LAYOUT */

.c-layout-wrapper {
	max-width: em($layout-wrapper-max-width);
	padding: 0 $bsu;
	margin: 0 auto;

	.c-layout-wrapper, .c-layout-aside & {
		padding: 0;
	}
}

.c-layout-page {
	position: relative;
	z-index: $z-page;
	padding-top: $lsu;
	overflow: hidden;

	.c-hero-home + & {
		padding-top: 0;
	}
	
	@include bp('large') {
		padding-top: 70px;
	}
}

.c-layout-main {

	.has-aside & {
		@include bp('large') {
			width: calc(100% - #{$layout-aside-width + $layout-aside-gutter-width--large});
			float: left;
		}

		@include bp('extra-large') {
			width: calc(100% - #{$layout-aside-width + $layout-aside-gutter-width--extra-large});
		}
	}
}

.c-layout-aside {

	@include bp('large') {
		width: $layout-aside-width + $layout-aside-gutter-width--large;
		float: left;
		padding-left: $layout-aside-gutter-width--large;
	}

	@include bp('extra-large') {
		width: $layout-aside-width + $layout-aside-gutter-width--extra-large;
		padding-left: $layout-aside-gutter-width--extra-large;
	}
}

.c-layout-header {
	margin-bottom: $lsu;

	h1 {
		margin-bottom: 0.3em;
	}

	&.show-guarantee {


		@include bp('medium') {
			padding-right: 50px;
		}
	}
}

.c-layout-header__lede {
	@include font-24-eb;
	font-size: 1.2em;
}

.c-layout-header__intro_content {
    @include font-20;
    margin-bottom: $bsu;
}

.c-layout-header__intro_image {
    padding-left: 0;
    margin-bottom: $bsu;

    @include bp('medium') {
        padding-left: $lsu;
    }
}

.c-layout-header__intro_lede {
    @include font-24-b;
    margin-bottom: $bsu;
}

.c-layout-header__guarantee-img {
    margin-top: -40px;
    float: right;
    margin-left: $bsu;

    @include bp('large') {
        margin-top: -70px;
    }

    @include bp('extra-large') {
        margin-left: 150px;
    }

    img {
        width: 100%;
        max-width: 100px;

        @include bp('medium') {
            max-width: 100%;
        }
    }
}