﻿/* CONTROL.CTA */

.c-cta {
	padding: $bsu;
	margin: $bsu 0;
	background: $brand;
	text-align: center;

	@include bp('medium') {
		display: flex;
		justify-content: center;
		align-items: center;
	}
}

.c-cta__body {

	*:last-child {
		margin: 0;
	}
}

.c-cta__heading {
	@include font-20-eb;
}

.c-cta__heading {
}

.c-cta__text {
}

.c-cta__link {
	margin-top: $ssu;

	@include bp('medium') {
		margin-left: $lsu;
		margin-top: 0;
	}
}