﻿/* CONTROL.EMBED */

.c-embed {
	#CDSWIDSSP {
		margin: 0 auto;

		@include bp('large') {
			margin-top: -18px; //hack code for the top gap of tripadvisor widget
		}
	}

	[style*="width:"] {
		max-width: 100%;
	}


	button[type="submit"] {
		@extend .o-btn;
		display: block;
		width: 100%;
		height: auto;
		background-color: $white;

		@include bp("medium") {
			display: inline-block;
			width: auto;
		}
	}


	// Campaign Monitor form - EXPLORE
	form[action*="www.createsend.com"] {
		margin: $lsu 0;
		max-width: 600px;
		// The embed code we have been given uses <div> elements with no attributes
		> div > div {
			margin: 0 0 $bsu;
		}

		label {
			display: block;
			margin-bottom: 5px;

			@include font-20-eb;
		}

		input,
		select,
		textarea {
			@include input;
		}
	}


	form#subForm {
		margin: $lsu 0;
		max-width: 600px;
		// The embed code we have been given uses <div> elements with no attributes
		> div > div {
			margin: 0 0 $bsu;
		}

		label {
			display: block;
			margin-bottom: 5px;
			@include font-20-eb;
		}

		input,
		select,
		textarea {
			@include input;
		}
	}
}

.c-embed__heading {
}
