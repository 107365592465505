/* DOLPHIN.COMPONENT.HEADER */

.c-header {
	background: $white;

	@include bp('large') {
		background: transparent;

		&.is-sticky, &.menu-expanded, &.lang-expanded {
			background: $white;
		}
	}
}

.c-header__wrapper {
	justify-content: flex-start;

	&:before {
		display: block;
		content: '';
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		background: $blue--dark;
		width: calc(100% - 100px);
		z-index: -1;

		@include bp('small') {
			width: 300px;
		}

		@include bp('large') {
			width: 160px;
		}

		@include bp(1440px) {
			width: calc((100vw - 1050px) / 2);
		}
	}

	&:after {
		display: block;
		content: '';
		position: absolute;
		top: 0;
		bottom: 0;
		left: calc(100% - 100px);
		width: 145px;
		height: 100%;
		z-index: -1;
		background: url('../images/interface/logos-dolphin/header-chevron.svg') no-repeat left center;
		background-size: contain;

		@include bp('small') {
			left: 298px;
		}

		@include bp('large') {
			left: 159px;
			height:auto;
		}

		@include bp(1440px) {
			left: calc((100vw - 1052px) / 2);
		}
	}

	@include bp('large') {
		//height: auto;
		//padding-bottom: 0;
		//padding-right: 140px;
		padding-right: 20px;
		transition: all 0.3s ease-in-out;

		&:before {
			display: none;
		}

		&:after {
			display: none;
		}

		.is-sticky & {
			padding-top: 0;

			&:before {
				display: block;
			}

			&:after {
				display: block;
			}
		}

		.menu-expanded & {
			&:before {
				display: block;
			}

			&:after {
				display: block;
			}
		}
	}

	@include bp('extra-large') {
		//height: auto;
		//padding-bottom: 0;
		//padding-right: 160px;
	}

	//@include bp(1730px) {
	//	padding-right: 20px;
	//}

	.c-book-now-btn {
		color: $white;
	}
}
// TODO: remove, just to make theme visable
@keyframes header-logo {
	from {
		opacity: 1;
	}

	to {
		opacity: 0;
	}
}

.c-header__logo {
	display: inline-block;
	width: 220px;

	.c-header__logo-img__short {
		width: 130px;
		height: 100%;
		margin-left: 10px;

		@include bp("small") {
			margin-left: 55px;
		}

		@include bp("large") {
			margin-left: 0px;
		}

		@include bp('extra-large') {
			display: none;

			.is-sticky & {
				display: block;
			}
		}
	}

	.c-header__logo-img__long {
		display: none;

		@include bp('extra-large') {
			display: block;
			width: 200px;
			height: 100%;
			margin-right: 20px;
			padding-bottom: 10px;

			.is-sticky & {
				display: none;
				padding-bottom: 0;
			}
		}
	}

	@include bp('extra-large') {
		margin-right: 150px;

		.is-sticky & {
			margin-right: 200px;
		}
	}
}
