﻿/* CONTROL.PODS */

.c-pods {
}

.c-pods__heading {
}

.c-pods__item {
	margin-bottom: $bsu;
}

// DEFAULT POD
.c-pod {
	background: $black;
	position: relative;
	height: 100%;
	min-height: 300px;
	overflow: hidden;

	@include bp('extra-large') {
		min-height: 450px;
	}
}

.c-pod__figure {
	height: 100%;
}

.c-pod__ribbon,
.c-product-pod__ribbon {
	position: absolute;
	left: 0;
	top: $ssu;
	padding: $ssu $bsu;
	border-radius: 0 $bsu $bsu 0;
	font-size: 16px;
	font-weight: $weight--bold;

	&--white {
		background-color: #fff;
		color: #222;
	}

	&--yellow {
		background-color: #ffe81c;
		color: #222;
	}

	&--pink {
		background-color: #b11989;
		color: #fff;
	}

	&--red {
		background-color: #aa0000;
		color: #fff;
	}
}

.c-pod__body {
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	padding-left: $bsu;
	padding-right: $bsu;
	color: $brand;
	min-height: 0;
	transition: all 0.4s ease-in-out;

	.no-touchevents .c-pod:hover & {
		background: $brand;
		min-height: 100%;
	}

	.c-pod__text {
		display: none;
	}

	@include bp("large") {
		.c-pod:hover & {
			background: $brand;
			min-height: 100%;
		}

		.c-pod__text {
			display: block;
		}
	}

	@include bp('extra-large') {
		padding-left: $ssu*3;
		padding-right: $ssu*3;
	}

	&.hasRibbon {
		@include bp-below("medium") {
			padding: 70px $bsu $bsu $bsu;
		}
	}

	&.hasCTA {
		@include bp-below("medium") {
			position: relative;
			height: 100%;
			padding: 70px $bsu $bsu $bsu;
			display: flex;
			justify-content: flex-end;
			flex-direction: column;

			.c-pod__heading, .c-pod__text {
				color: #fff;
			}
		}
	}
}

.c-pod__info {
	padding: $bsu 0;

	@include bp('extra-large') {
		padding-top: $ssu*3;
		padding-bottom: $ssu*3;
	}
}

.c-pod__heading {
	@include font-30-b;
	color: $white;
	transition: all 0.4s ease-in-out;

	.no-touchevents .c-pod:hover & {
		color: $body-color;
	}

	@include bp("large") {
		.c-pod:hover & {
			color: $body-color;
		}
	}
}

.c-pod__detail {
	@include bp('large') {
		max-height: 0;
		height: 0;
		margin: 0;
	}
}

.c-pod__text {
	@include font-20;
	color: $body-color;

	.hasRibbon & {
		@include bp-below('large') {
			display: none;
		}
	}
}

.c-pod__meta {
	@include font-18;
	color: $white;
	transition: all 0.4s ease-in-out;

	.no-touchevents .c-pod:hover & {
		color: $body-color;
	}

	@include bp("large") {
		.c-pod:hover & {
			color: $body-color;
		}
	}
}

.c-pod-bg {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background-position: 50% 50%;
	background-size: cover;
	opacity: .85;
	transition: opacity .3s ease;
}
// NO IMAGE POD
.c-pod-noimage {
	height: 100%;
	background: $brand;
	position: relative;
}

.c-pod-noimage__body {
	padding: $bsu;
	color: $body-color;

	&.hasRibbon {
		padding-top: 70px;
	}
}

.c-pod-noimage__heading {
	@include font-30-b;


	@include bp-below('large') {
		.hasRibbon & {
			margin-bottom: 40px !important;
		}
	}
}

.c-pod-noimage__text {
	.hasRibbon & {
		@include bp-below('large') {
			display: none;
		}
	}
}
// QUICKLINKS POD
.c-pod-quicklinks {
	height: 100%;
	background: $brand;
}

.c-pod-quicklinks__body {
	padding: $bsu;
	color: $white;

	@include bp('extra-large') {
		padding: $ssu*3;
	}
}

.c-pod-quicklinks__heading {
	margin-bottom: 0.5em;
}

.c-pod-quicklinks__item {
	position: relative;
	padding-left: 2em;
	margin-bottom: 0.5rem;
}

.c-pod-quicklinks__icon {
	position: absolute;
	left: 0;
	top: 0.20rem;
}

.c-pod-quicklinks__link {
	color: $white;

	&:hover,
	&:focus {
		text-decoration: underline;
	}
}
//product pod
.c-product-pod {
	display: block;
	position: relative;
	height: 100%;
	min-height: 300px;
	overflow: hidden;

	&:after {
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background: linear-gradient(to bottom, rgba(0,0,0,0.5), rgba(0,0,0,0.1) 30%, rgba(0,0,0,0) 100%);
	}

	@include bp('extra-large') {
		min-height: 450px;
	}
}

.c-product-pod-wrapper {
	.c-listing & {
		display: none;
	}
}

.c-product-pod-bg {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background-position: 50% 50%;
	background-size: cover;
	transition: opacity .3s ease;
}

.c-product-pod__body {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	padding: $bsu;
	z-index: 2;

	@include bp('extra-large') {
		padding: $ssu*3;

		.is-large & {
			padding: $ssu*3;
		}
	}

	&.hasRibbon {
		padding: 70px 30px 30px;
	}
}

.c-product-pod__title {
	@include font-30-eb-1;
	color: $white;

	@include bp-below("medium") {
		display: none;
	}
}

.c-product-pod__location {
	@include font-14-1;
	color: $white;

	@include bp-below("medium") {
		display: none;
	}
}

.c-product-pod__location__icon {
	color: $brand;
}

.c-product-pod__feature {
	position: absolute;
	right: 0;
	bottom: 0;

	display: none;

	@include bp(500px){
		display: block;
	}
}

.c-product-pod__feature-item {
	position: relative;
	display: flex;
	align-items: center;
	padding-left: 55px;
	background: $brand;
	width: 145px;
	height: 45px;

	& + & {
		margin-top: 3px;
	}
}

.c-product-pod__feature-item__icon {
	@include square(45px);
	position: absolute;
	left: 0;
	top: 0;
	background: $grey--dark;
	color: $brand;
	font-size: 30px;

	&:before {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
}

.c-product-pod__feature-item__text {
	color: $body-color;
}

.c-product-pod__feature-time {
	@include font-18-eb;
}

.c-product-pod__feature-from {
	@include font-12;

	.zh-CN & {
		line-height: 1;
	}
}

.c-product-pod__feature-price {
	@include font-20-eb;
	margin-top: -3px;

	.zh-CN & {
		margin-top: 0;
	}
}

.c-product-pod-hover {
	position: absolute;
	z-index: 2;
	background: $brand;
	width: 100%;
	height: 100%;
	padding: $bsu;
	color: $body-color;
	transition: all 0.4s ease-in-out;
	transform: translateY(100%);


	.no-touchevents .c-product-pod:hover & {
		transform: translateY(0);
	}

	@include bp("large") {
		.c-product-pod:hover & {
			transform: translateY(0);
		}
	}

	@include bp("extra-large") {
		padding: $ssu*3;
	}

	@include bp-below("medium") {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		position: relative;
		background: none;
		transform: translate(0%);
		background: transparent !important;

		.c-product-pod-hover__location {
			color: $white;

			span {
				color: $brand;
			}
		}

		.c-product-pod-hover__title, .c-product-pod-hover__abstract {
			color: $white;
		}

		.o-btn {
			background: $brand;

			&:hover {
				background: $white;
				border: 1px solid $brand;
			}

			&.o-btn-CTA {
				background: #333333;
				color: $white;

				&:hover {
					background: $white;
					color: #333;
					border: 1px solid $brand;
				}
			}
		}
	}
	
}

.c-product-pod-hover__title {
	@include font-30-eb-1;
}

.c-product-pod-hover__location {
	margin-top: $tsu;
}

.c-product-pod-hover__abstract {
	@include font-18;
	margin-top: $bsu;

	@include bp-below('large') {
		.hasRibbon & {
			display: none;
		}
	}

	@include bp('large') {
		display: none;
	}

	@include bp('extra-large') {
		display: block;
	}
}

.c-product-pod__buttons {
	a {
		margin-bottom: 10px;
	}

	.hasRibbon & {
		@include bp-below('large') {
			margin-top: $lsu;
		}
	}
}
// POD PROMO
.c-pod-promo__body {
	padding: $bsu;
	color: $white;

	@include bp ("extra-large") {
		padding: $ssu*3;
	}
}

.c-pod-promo__heading {
	@include font-30-b;
	color: $brand;
}

.c-pod-promo__text {
	@include font-18;
	margin: $bsu 0;
}

.c-pod-promo__btn {
	color: $brand;
	background-color: transparent;

	&:hover {
		color: $black;
		background-color: $brand;
		border-color: $brand;
	}
}
