.o-btn {
	border: 1px solid $blue--dark;
	color: $white;
	background: $brand;
	text-shadow: 0px 0px 1px rgba(0,0,0,0.5);

	&:hover {
		border: 1px solid $btn-purple;
		background: $btn-purple;
		color: $white;
	}
}

.o-btn--on-yellow {
	background: $blue--dark;
	color: $white;

	&:hover {
		border-color: $btn-purple;
		background: $btn-purple;
		color: $white;
	}
}
