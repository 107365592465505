﻿/* CONTROL.FORM */

.c-form {
	padding: 10px 20px;

	.umbraco-forms-form {
		padding: 0;
	}


	.umbraco-forms-fieldset {
		padding: 0;
		margin: 0;
		max-width: 100%;

		.col-md-12 {
			max-width: 600px;
		}

		.col-md-6 {
			width: 100%;

			@include bp('medium') {
				width: 45%;
				display: inline-block;
			}
		}

		.col-md-6 + .col-md-6 {

			@include bp('medium') {
				margin-left: 10px;
			}
		}

		.umbraco-forms-field {

			input, select, textarea, .select {
				@include input;
			}

			input[type="checkbox"],
			input[type="radio"] {
				display: inline-block;
				width: auto !important;
				height: auto;
				line-height: 1;
			}

			input[type="file"] {
				padding: 10px;
				height: auto;
				font-size: 12px;
				line-height: 1.5;
			}

			input {
				// line-height: 53px;
			}

			textarea {
				height: 150px;
				max-width: none !important;
				resize: vertical;
				padding: $ssu $bsu;
				border: 1px solid $grey;
			}

			input.text {
				max-width: none !important;
			}

			select, .select {
				padding-left: $ssu;
				padding-right: $bsu;

				&::-ms-expand {
					display: none;
				}
			}

			input, select, textarea {
				&.input-validation-error {
					border-color: $error;
					background-color: $white;
					color: $body-color;
				}
			}

			.contourIndicator {
				color: $brand;
			}

			&.checkbox {
				position: relative;
				padding-left: 56px;

				> div {
					margin-bottom: 30px;
				}

				.c-fake-checkbox__wrapper {
					position: absolute;
					top: 0;
					left: 0;
				}

				.field-validation-error {
					padding-left: 0;
				}
			}

			span.field-validation-error {
				display: block;
				padding-left: 0;
				color: $error !important;
				font-size: rem(12px);
			}

			.checkboxlist {
				padding: 10px 0 !important;
				float: none !important;

				label {
					float: none !important;
				}
			}
		}
	}

	.title {
		h2 {
			margin-bottom: $bsu;
			font-size: rem(50px);
		}

		p {
			display: block;
			margin-top: -25px;
			font-size: rem(15px);
		}
	}

	.umbraco-forms-field {
		margin: 0 0 $bsu;
	}

	.umbraco-forms-label {
		display: block;
		margin: 0 0 $ssu;
		font-size: rem(15px);
		text-transform: uppercase;
	}

	input[type="submit"] {
		@extend .o-btn;
		display: block;
		width: 100%;
		height: auto;
	}

	.contourMessageOnSubmit {
		margin-bottom: 0;
	}

	label.umbraco-forms-label {
		@include font-20-eb;
		margin-bottom: 5px;
	}

	.umbraco-forms-caption {
		@include font-24-eb-formCaption;
		margin-bottom: $bsu;
	}

	@include bp('medium') {

		input[type="submit"] {
			display: inline-block;
			width: auto;
		}
	}
}


// Fake Checkbox
%c-fake-option__wrapper {
	@extend .u-cf;
	position: relative;
}

%c-fake-option {
	// Hide, but still allow access through tabbing
	position: absolute !important;
	border: 0 !important;
	clip: rect(0 0 0 0) !important;
	overflow: hidden !important;
	padding: 0 !important;
	height: 1px !important;
	width: 1px !important;
	margin: -1px !important;
}

%c-fake-option__display {
	display: block;
	width: 40px;
	height: 40px;
	border: 4px solid $black;
	text-align: center;
	line-height: 40px;
}

.c-fake-checkbox__wrapper {
	@extend %c-fake-option__wrapper;
}

.c-fake-checkbox {
	@extend %c-fake-option;

	&:checked ~ .c-fake-checkbox__display {
		&:before {
			@extend %iconf;
			content: $iconf-close;
		}
	}
}

.c-fake-checkbox__display {
	@extend %c-fake-option__display;
	margin-right:20px;

	&:before {
		font-size: 21px;
	}
}

// Fake Radio
.c-fake-radio__wrapper {
	@extend %c-fake-option__wrapper;
}

.c-fake-radio {
	@extend %c-fake-option;

	&:checked ~ .c-fake-radio__display {
		&:before {
			background: $black;
		}
	}
}

.c-fake-radio__display {
	@extend %c-fake-option__display;
	position: relative;
	border-radius: 40px;

	&:before {
		content: '';
		position: absolute;
		left: 6px;
		top: 6px;
		display: block;
		width: 20px;
		height: 20px;
		border-radius: 24px;
		background: transparent;
		transition: background 0.2s ease-in-out;
	}
}

//umbraco-forms-submitmessage

.umbraco-forms-submitmessage {
	margin-bottom: $bsu;

	@include bp('large') {
		margin-bottom: $lsu;
	}
}