﻿/* COMPONENT.HERO */

@use "sass:math";

//hero with single image

.c-hero {
	position: relative;
	padding-bottom: 100%;
	height: 0;
	overflow: hidden;

	.whitsunday-blue & {
		&:before {
			position: absolute;
			display: block;
			content: '';
			top: 0;
			bottom: 0;
			left: -800px;
			width: 1000px;
			background: rgba(0,57,139, 0.6);
			transform: skew(-35deg);
			z-index: 2;

			@include bp('medium') {
				left: -700px;
			}

			@include bp('large') {
				left: -600px;
			}
		}

		&:after {
			position: absolute;
			display: block;
			content: '';
			top: 0;
			bottom: 0;
			left: -700px;
			width: 1000px;
			background: rgba(0,57,139, 0.2);
			transform: skew(-35deg);
			z-index: 2;

			@include bp('medium') {
				left: -600px;
			}

			@include bp('large') {
				left: -500px;
			}
		}
	}

	@include bp('large') {
		padding-bottom: percentage(math.div(540, 1920));
	}
}

.c-hero__overlay {
	position: absolute;
	display: block;
	content: '';
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background: linear-gradient(to bottom, rgba(0,0,0,0.7), rgba(0,0,0,0.15) 20%, rgba(0,0,0,0.1) 100%);
	z-index: 1;
}

.c-hero__heading {
	@include font-80-eb;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	color: $white;
	z-index: 3;

	strong {
		display: block;
		color: $brand;
		font-weight: 700;
	}
}

.c-hero-gallery__btn-wrapper {
	position: absolute;
	bottom: 0;
	width: 100%;
	margin-bottom: $bsu;
	z-index: 2;
}

//for home page hero include video
.c-hero-home {
	position: relative;

	&:after {
		position: absolute;
		display: block;
		content: '';
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background: linear-gradient(to bottom, rgba(0,0,0,0.7), rgba(0,0,0,0.1) 30%, rgba(0,0,0,0.1) 85%, rgba(0,0,0,0.1) 100%);
	}
}

.c-hero-home__viewport {
	padding-bottom: calc(100vh - 200px);

	@include bp("medium") {
		padding-bottom: calc(100vh - 150px);
	}
}

.c-hero-home__items {

	.slick-list,
	.slick-track {
		height: 100%;
	}

	.slick-arrow {
		display: none !important;
	}

	@include bp('large') {

		.slick-arrow {
			display: block !important;
		}
	}
}

.c-hero-home__video {
	position: absolute;
	top: 50%;
	left: 50%;
	z-index: -100;
	width: auto;
	height: auto;
	min-width: 100%;
	min-height: 100%;
	transform: translateX(-50%) translateY(-50%);
}

.c-hero-home__header{
	@include font-140-eb;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	color: $white;
	z-index: 1;

	strong {
		display: block;
		color: $brand;
	}

	.zh-CN & {
		line-height: 1;
	}
}

.c-hero-home__cta {
	margin-top: $lsu;
	border: 0;
}
