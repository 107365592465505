﻿/* CONTROL.VIDEO */

@use "sass:math";

.c-video {
}

.c-video__heading {
}

.c-video__embed {
	padding-bottom: percentage(math.div(9, 16));
}

.c-video__cover {
	width: 100%;
}

.c-video__icon {
	font-size: 40px;
	color: $white;

	@include bp('small') {
		font-size: 60px;
	}

	@include bp('medium') {
		font-size: 80px;
	}
}

.c-video__figcaption {
}