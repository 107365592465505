﻿/* CONTROL.HEADING */

.c-feature-article__wrapper {
    margin: $lsu*2 0 $lsu*2 0;

    &.has-heading {
        margin: $lsu 0;
    }

    @include bp('medium') {
        margin-top: $lsu*3;
        margin-bottom: $lsu*3;

        &.has-heading {
            margin-bottom: $bsu*3;
        }
    }

    @include bp('extra-large') {
        margin-top: $lsu*4;
        margin-bottom: $lsu*4;

        &.has-heading {
            margin-bottom: $lsu*2;
        }
    }
}


.c-feature-article {
	background: $brand;

	.has-heading & {
		margin-top: ($lsu*2 - 10);
	}

	@include bp('large') {
		position: relative;
	}

	@include bp('extra-large') {
		margin-top: $lsu*2;
	}
}

.c-feature-article__body-wrapper {
	@include bp('large') {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
	}
}

.c-feature-article__body {
	@include bp('large') {
		position: relative;
		height: 100%;
	}
}

.c-feature-article__content {
    padding-top: 46px;
    padding-bottom: 36px;
    padding-left: $bsu;
    padding-right: $bsu;

    @include bp('medium') {
        padding-top: 36px;

    }

    @include bp('large') {
        position: absolute;
        right: $bsu;
        top: 50%;
        transform: translateY(-50%);
        width: 50%;
        text-align: right;
        padding-left: $bsu*3;
    }

    @include bp('extra-large') {
        right: 0;
        padding-left: $lsu*2;
    }

    .image-right & {

        @include bp('large') {
            left: $bsu;
            text-align: left;
            padding-right: $bsu*4;
            padding-left: $lsu;
        }

        @include bp('extra-large') {
            left: 0;
            padding-right: $lsu*3;
            padding-left: $lsu;
        }
    }
}
.c-feature-article-meta {
	@include font-16-eb;
}

.c-feature-article-title {
	@include font-60-eb2;
}

.c-feature-article-abstract {
	@include font-20;
	margin-top: $bsu;

	@include bp('extra-large') {
		margin-top: $lsu;
	}
}

.c-feature-article__btn {
	margin-top: $bsu;

	@include bp('extra-large') {
		margin-top: $lsu;
	}
}

.c-feature-article__sacle-image {
    position: relative;
    transform: scale(1.1);

    @include bp('medium') {
        transform: scale(1.2);
        right: -$bsu;
    }
}