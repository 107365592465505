﻿/* CONTROL.GOOGLE-MAP */

.c-google-map {
	position: relative;

	&.has-detail-pod {
		background: $brand;
	}
}

.c-google-map__heading {
	margin-bottom: $bsu;

	@include bp('extra-large') {
		margin-bottom: $lsu;
	}
}

.c-google-map__embed {
	padding-bottom: 500px;

	.has-detail-pod &{
		@include bp('medium') {
			padding-bottom: 0;
			min-height: 600px;
			height: 100%;
		}
	}
}

.c-google-map__content {
}

.c-google-map__contact-pod-wrapper {
	@include bp('large') {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
	}
}

.c-google-map__contact-pod {
	padding: $bsu 0;

	@include bp('medium') {
		padding: 30px 0;
		max-width: 350px;
	}

	@include bp('large') {
		max-width: 300px;
	}

	@include bp('extra-large') {
		padding: 50px 0;
		max-width: 380px;
	}
}

.c-google-map__contact-item {
	position: relative;
	padding-left: 45px;
	margin-bottom: $bsu;

	&:last-of-type {
		margin-bottom: 0;
	}
}

.c-google-map__contact-icon {
	position: absolute;
	font-size: 22px;
	left: 0;
	top: 3px;
}

.c-google-map__contact-title {
	@include font-18-eb;
	margin-bottom: 5px;
	text-transform: uppercase;
}

.c-google-map__contact-detail {
	@include font-18;
	margin-bottom: 5px;

	&.is-link {
		color: $body-color;

		&:hover {
			text-decoration: underline;
		}
	}

	&.is-email {
		@include font-18-eb;
		color: $blue;
	}

	&.is-fax {
		color: $body-color;

		a {
			color: $body-color;
		}
	}

	&.free-phone {
		@include font-24-eb;
	}
}

.c-google-map-address-link {
	@include font-12-eb;
	color: $blue;

	&:hover {
		text-decoration: underline;
	}
}
