﻿/* COMPONENT.SHARE */

$share__icon-size: 46px;
$share__icon-font-size: 20px;


.c-share {
	margin: $bsu auto;

	@include bp('large') {
		position: fixed;
		right: 0;
		top: 10%;
		z-index: $z-share;
	}
}

.c-share--floating {
	transition: left 0.3s ease-in;
	position: fixed;
	left: -#{$share__icon-size};
	top: 200px;
	z-index: $z-share;
	width: $share__icon-size;
	padding: 0;
	margin: 0;

	&.is-visible {
		left: 0;
	}
}

.c-share__heading {
	display: block;
	margin: 0 0 $hsu;

	.c-share--floating & {
		display: none;
	}
}

.c-share__icon {
	@include link-transition;
	@include square($share__icon-size);
	display: block;
	font-size: $share__icon-font-size;
	text-align: center;
	vertical-align: middle;
	color: $grey--dark;
	background: $grey--light;
	float: left;
	margin-right: 2px;

	&:focus {
		outline: none;
	}

	&:hover {
		background: $brand;
	}

	@include bp('large') {
		float: none;
		margin-top: 1px;
		margin-right: 0;
	}
}
