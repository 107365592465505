/* COMPONENT.RELATED-ITEMS */

.c-related-items {
}

.c-related-items__heading {
}

.c-related-items__intro {
	margin-bottom: $bsu;

	p:last-child {
		margin-bottom: 0;
	}
}

.c-related-items {
	@include ui-border('top');
}

.c-related-item__link {
	display: block;
	position: relative;
	padding: $ssu;
	padding-left: $ssu * 5;
	cursor: pointer;
	@include ui-border('bottom');
	color: $body-color;


	&:hover,
	&:focus {
		outline: none;

		.c-related-item__icon-field {
			background: darken($brand,10);
		}
	}

	@include bp('medium') {
		padding: $bsu;
		padding-left: $bsu * 4;
	}

	@include bp('large') {
		padding-left: $ssu * 9;
	}
}

.c-related-item__name {
	@include font-24-eb;
}
.c-related-item__icon {
	position: absolute;
	left: 50%;
	top: 50%;
	font-family: 'font-icons';
	font-size: 16px;
	transform: translate(-50%, -50%);

	@include bp('medium') {
		font-size: 20px;
	}
}

.c-related-item__meta {
	@include font-16;
	margin-left: $tsu;

	@include bp('medium') {
		margin-left: $bsu;
	}
}

.c-related-item__icon-field {
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	width: 40px;
	background: $brand;

	@include bp('medium') {
		width: 60px;
	}

	@include bp('large') {
		width: 70px;
	}
}
