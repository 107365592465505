// COMPONENT.MEGAMENU

$megamenu-max-width: 1600px;

.c-megamenu {
	background: $white;
	color: $white;
	/*	@include bp('large') {
		top: 94px;

		.is-sticky & {
			top: 62px;
		}
	}*/
}

.c-megamenu__wrapper {

	@include bp('large') {
		.c-navigation__item--one & {
		}
	}
}


//experience
.c-megamenu-experiences-grouped { //grouped
	padding-top: 0;
}

.c-megamenu-experience { //grouped item
}

.c-megamenu-experiences { //not grouped
}

.c-megamenu-experience__heading {
	color: $blue--dark;
}

.c-megamenu-experience-item {
	@include bp('large') {
		&:hover {
			border-color: $brand;

			.c-megamenu-experience-title {
				color: $brand;
			}
		}
	}
}

.c-megamenu-experience-item__link {
}

.c-megamenu-experience-item__title {
	font-family: $base-font-family;
	color: $brand;
	z-index: 2;
	&:hover {
		color: $blue;
	}
}

// PROMO TILES
.c-promo {
}

.c-promo-item {
}

.c-promo-item__link {
}

.c-promo-item__image {
}

.c-promo-item__title {
	color: $brand;

	.c-promo-item:hover & {
		color: $blue;
	}
}

.c-promo-item__icon {
	color: $white;
}


//view all
.c-megamenu__view-all,
.c-megamenu-view-all__link {
	color: $brand;
	&:hover {
		color: $blue;
	}
}
