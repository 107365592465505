﻿.c-experience-feature {
	background: $brand;
}

.c-experience-feature__items {
	display: flex;
	flex-wrap: wrap;
	color: $blue--dark;

	@include bp('extra-large') {
		border-right: 1px solid $blue;
	}
}

.c-experience-feature__item {
	display: inline-block;
	position: relative;
	float: left;
	padding-top: $ssu;
	padding-bottom: $ssu;
	padding-left: 60px;
	padding-right: $tsu*3;
	width: 100%;
	border-top: 1px solid $blue;

	&:first-child {
		border-top: 0px none;
	}

	&.long__item {
		border-top: 1px solid $blue;
	}

	@include bp('medium') {
		width: 33%;
		border-left: 1px solid $blue;
		border-top: 0px none;

		&.long__item {
			width: 50%;
		}
	}

	@include bp('extra-large') {
		width: 20%;
		border-top: 0px none;

		&.long__item {
			width: 20%;
			border-top: none;
		}
	}
}

.c-experience-feature__item-icon {
	@include square(45px);
	position: absolute;
	left: 0;
	top: 0;
	color: $blue--dark;
	font-weight: bold;
	font-size: 30px;

	&:before {
		font-weight: 600 !important;
	}

	&:before {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
}

.c-experience-feature__item-label {
	@include font-12-b-1;
	color: #eee;
}

.c-experience-feature__item-text {
	@include font-20-eb;
	margin-top: $tsu;
	color: $white;
}


.c-experience-feature__item-price {
	display: flex;
	.c-experience-feature__item-label{
		margin-top: -4px;
	}
}

.c-experience-feature-price-item {
	& + & {
		margin-left: $bsu;
	}
}