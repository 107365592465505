/* COMPONENT.PRODUCTLIST */

.c-product {
	margin-bottom: 20px;

	@include bp('medium') {
		margin-bottom: 40px;
	}
}

.c-product-info {
	background-color: $blue--dark;
	padding: 1.25rem;
	color: $white;

	@include bp('medium') {
		padding: 2.5rem;
	}

	.o-grid--full {
		align-items: center;
	}

	.c-product-info__detail {
		padding-right: 0;

		@include bp('medium') {
			padding-right: 5rem;
		}
	}
}

.c-product-info__name {
	@include font-30-eb;
	color: $white;
	padding-bottom: 15px;
	margin-bottom: 25px;
	border-bottom: 1px solid $brand;
}

.c-product-info__bordered {
	padding-bottom: 10px;
	margin-bottom: 25px;
	border-bottom: 1px solid $brand;
}

.c-product-info__expander {
	border: none;
	margin-bottom: 20px;

	@include bp('medium') {
		margin-bottom: 0;
	}
}

.c-product-info__view {
	display: block;

	@include bp('medium') {
		display: none;
	}
}

.js .c-product-info__expanded__items {
	display: none;
	padding: 0;
	border: none;
	margin: 20px 0;

	@include bp('medium') {
		display: block;
		margin: 0;
	}
}

.c-product-info__times-title {
	@include font-16-eb;
}

.c-product-info__times-content {
	@include font-18;

	a {
		color: $brand;

		&:hover,
		&:focus {
			text-decoration: underline;
		}
	}
}

.c-product-info__prices_heading {
	@include font-16-eb;
	text-transform: uppercase;
	margin-bottom: 20px;
}

.c-product-info__prices {
	margin-bottom: 20px;
	margin-right: 20px;
}

.c-product-info__price {
	@include font-30-eb;

	.c-product-info__price_type {
		@include font-18;
		text-transform: uppercase;
	}
}

.c-product-info__fineprint {
	a {
		color: $brand;

		&:hover,
		&:focus {
			text-decoration: underline;
		}
	}
}

// Booking Form style
.c-product-booking {
	padding-right: 0;
	margin-bottom: 20px;

	@include bp('large') {
		padding-right: 5rem;
		margin-bottom: 0;
	}
}

.c-product-info__subtitle {
	@include font-16-eb;
	display: block;
	margin-bottom: 5px;
}

.c-product-info .product-date-input,
.c-product-info .c-product-type select,
.c-product-info .c-booking-widget-guest-num {
	display: block;
	width: 100%;
	height: 46px;
	line-height: 46px;
	padding: 0 20px;
	border: 1px solid $white;
	border-radius: 0;
	color: $body-color;
	margin-bottom: $bsu;
}

.c-product-info__btn {
	background-color: $brand;
	border: 1px solid $brand;
	border-radius: 0;

	&.c-loading {
		opacity: 0.65;
		cursor: not-allowed;
	}
}

.datepicker-container {
	position: relative;

	&::before {
		font-family: font-icons !important;
		content: "\e925";
		position: absolute;
		bottom: 8px;
		right: 10px;
		font-size: 25px;
		color: $body-color;
		pointer-events: none;
	}
}

// product-avail-table

.c-product-avail-table {
	display: none;

	.c-product.is-expanded & {
		display: block;
	}
}

.c-product-avail-table table {
	width: 100%;
}

.c-product-avail-table thead th {
	background-color: $grey--dark;
	color: $white;
}

.c-product-avail-table thead .th-date-prev {
	position: relative;
	text-align: left;
	padding-left: 30px;

	a {
		right: 20px;
		position: absolute;
		color: $white;
	}
}

.c-product-avail-table thead .th-date-next {
	a {
		color: $white;
	}
}

.c-product-avail-table thead .th-date a {
	display: block;
	padding-top: 10px;
	padding-bottom: 10px;
	color: $white;
	text-transform: uppercase;

	&.selected {
		background-color: $brand;
		color: $grey--dark;
	}

	&:hover {
		background-color: $white;
		color: $grey--dark;
	}
}

.c-product-avail-table tbody tr:hover .td-title {
	background-color: $white;
	border-color: $white;
}

.c-product-avail-table td {
	padding: 10px;
	border: 1px solid $grey;
	text-align: center;
	background-color: $grey;

	&.td-empty {
		background-color: $grey;
	}

	&.td-title {
		text-align: left;
		padding-left: 30px;
	}

	&.is-selected {
		background-color: $grey--light-2;
		border-color: $grey--light-2;
	}

	&.fully-booked,
	&.no-departure,
	.td-title-time {
		@include font-16-b;
		//background-color: $grey;
	}
}

.c-product-avail-table td.is-selected.selected {
	background-color: $white;
	border-color: $white;
}

.c-product-avail-table td.td-expanded {
	background-color: $white;
	border-color: $white;
}

.c-product-avail-table .avail-book-btn {
	@include font-14;
	padding: 4px 20px;
	border: none;
	border-radius: 2px;
	outline: 0;
	color: $black;
	background-color: $brand;
	margin-bottom: 5px;
}

.avail-insert-book-row {

	.avail-insert-book {
		padding: 15px;
		position: relative;

		.avail-insert-book-close {
			position: absolute;
			display: block;
			color: $black;
			right: 0;
			top: 0;
			font-size: 25px;
		}

		@include bp('large') {
			padding: 30px;
		}
	}

	.td-empty {
		display: none;

		@include bp('medium') {
			display: table-cell;
		}
	}
}


.c-product-avail-table td.limited .avail-book-btn {
	position: relative;

	&::after {
		content: '';
		position: absolute;
		right: 0;
		top: 0;
		display: block;
		width: 0;
		height: 0;
		border: 9px solid transparent;
		border-top-color: $error;
		border-right-color: $error;
	}
}

.td-show-book {
	position: relative;

	&.selected {
		background-color: $white;
		border-color: $white;
	}

	.td-book-status {
		position: absolute;
		left: 0;
		bottom: 2px;
		display: block;
		width: 100%;
		@include font-10-b;
		text-align: center;
		color: $error;
	}
}

.avail-book-btn {
	display: inline-block;

	.td-show-book.selected & {
		display: none;
	}
}

.td-show-book-detail {
	display: none;

	.td-show-book.selected & {
		display: block;
	}
}

.c-product-avail-table .promo-code-input {
	display: block;
	width: 100%;
	height: 46px;
	line-height: 46px;
	padding: 0 $bsu;
	border: 1px solid $grey;
	border-radius: 0;
	color: $body-color;
	margin-bottom: $bsu;
}

.c-product-avail-table .add-to-cart__btn {
	width: 100%;
	background-color: $brand;
	border-color: $brand;

	&:hover {
		background-color: $white;
		border-color: $black;
	}
}

// avail booking
.avail-insert-book {
	text-align: left;

	.o-grid {
		justify-content: center;
	}

	.price-type {
		@include font-16-eb;
		display: inline-block;
		margin-right: 5px;
	}

	.price-value {
		@include font-16;
	}

	.c-booking-widget-calcu {
		position: relative;
		margin: $tsu 0 $bsu;
	}

	.total-price {
		@include font-18-eb;
		display: inline-block;
		margin-right: 5px;
	}

	.promo-lable {
		display: inline-block;
		margin-top: $tsu;
		margin-bottom: $tsu;
	}
}

.price-block {
	margin-bottom: $bsu;

	@include bp('large') {
		margin-bottom: $tsu;
	}
}

.c-booking-widget-guest-num {
	display: block;
	width: 100%;
	height: 46px;
	line-height: 46px;
	padding: 0 $bsu;
	border: 1px solid $grey;
	border-radius: 0;
	color: $body-color;
	margin-bottom: $bsu;
	margin-top: $tsu;
}

.c-booking-widget-calcu-plus,
.c-booking-widget-calcu-minus {
	@include font-14;
	position: absolute;
	right: 14px;
	cursor: pointer;
}

.c-booking-widget-calcu-plus {
	top: 5px;
}

.c-booking-widget-calcu-minus {
	bottom: 0;
}
