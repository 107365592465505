/* COMPONENT.COOKIE-CONFIRM */

.c-cookie-confirm {
	position: fixed;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 1;
	display: none;
	background: $white;
}

.c-cookie-confirm__body {
	padding: $bsu $bsu $bsu 0;

	@include bp('medium') {
		display: flex;
		align-items: center;
	}

	@include bp("large") {
		position: relative;
		padding-right: $lsu;
	}
}

.c-cookie-confirm__message {
	flex: 1 1 auto;
	margin: 0;
	margin-bottom: $ssu;

	a {
		color: $blue;

		&:hover {
			text-decoration: underline;
		}
	}

	@include bp('medium') {
		margin-bottom: 0;
	}
}
.c-cookie-confirm__button {
	flex: 0 1 auto;
	margin-left: $ssu;
	padding: $tsu $bsu;
}

.c-cookie-confirm__close {
	position: absolute;
	top: 0;
	right: 0;
	padding: $ssu;
	background: none;
	border: none;
	cursor: pointer;
	&:before {
		@extend %iconf;
		content: $iconf-close;
	}
}