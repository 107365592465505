.c-loading {
	position: relative;

	> * {
		opacity: 0.3;
	}

	.csstransforms & {
		&:before,
		&:after {
			content: '';
			display: block;
			height: 20px;
			width: 20px;
			background-color: $brand;
			position: absolute;
			top: 25%;
			left: 50%;
			z-index: 5;
			border-radius: 50%;
			animation: rotate 1s linear infinite;
		}

		&:before {
			transform-origin: 60px 50%;
			margin-left: -40px;
		}

		&:after {
			transform-origin: -40px 50%;
			margin-left: 40px;
		}
	}
}

@-webkit-keyframes rotate {
    from {
        -webkit-transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
    }
}

@-moz-keyframes rotate {
    from {
        -moz-transform: rotate(0deg);
    }

    to {
        -moz-transform: rotate(360deg);
    }
}

@keyframes rotate {
    from {
        -ms-transform: rotate(0deg); /* IE 9 */
        -webkit-transform: rotate(0deg); /* Chrome, Safari, Opera */
        transform: rotate(0deg);
    }

    to {
        -ms-transform: rotate(360deg); /* IE 9 */
        -webkit-transform: rotate(360deg); /* Chrome, Safari, Opera */
        transform: rotate(360deg);
    }
}
