/* CONTROL.LISTING-TILED */

.c-listing-tiled {
	margin: $bsu 0;
}

.c-listing-tiled__item {
	margin-bottom: $bsu;

	&:last-child {
		margin: 0;
	}
}

.c-listing-tiled__item-heading {
	margin-bottom: 0.3em;
}

.c-listing-tiled__item-meta {
	display: block;
	margin-bottom: 0.5em;
}

.c-listing-tiled__item-body {

	> *:last-child {
		margin-bottom: 0;
	}
}