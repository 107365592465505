/* OBJECT.GRID */

// Config
//-------
// Adjust values for different grid spacings
// Custom key/value pairs can also be added
// Remove any grid spacings not being used to reduce bloat
$grid-gutters: (
	'default': $bsu,
	'huge': $bsu*3,
	'loose': $lsu,
	'tight': $ssu,
	'full': 0
);

//-------

@mixin grid-reset {
	padding: 0;
	margin: 0;
	list-style: none;
}

@mixin grid ($gutter) {
	@include flex-extend;
	@include flex-wrap;
	@include grid-reset;
	margin-left: -$gutter;
	font-size: 0;
}

// .o-grid example usage
//----------------------
/*
	<ul class="o-grid">
		<li class="o-grid__item u-1/3">...Content...</li>
	</ul>
*/

@each $modifier, $gutter in $grid-gutters {

	@if $modifier == 'default' {

		.o-grid {
			@include grid($gutter);
		}

		.o-grid__item {
			display: inline-block;
			width: 100%;
			padding-left: $gutter;
			vertical-align: top;
			font-size: $base-font-size;
		}

	} @else {

		.o-grid--#{$modifier} {
			@include grid($gutter);

			> .o-grid__item {
				padding-left: $gutter;
			}
		}
	}
}

@each $modifier, $gutter in $grid-gutters {

	@if $modifier == 'default' {

		.o-grid--v {
			margin-top: -$gutter;

			> .o-grid__item {
				padding-top: $gutter;
			}
		}
	}
	@else {

		.o-grid--v-#{$modifier} {
			margin-top: -$gutter;

			> .o-grid__item {
				padding-top: $gutter;
			}
		}
	}
}

.o-grid--center {
	text-align: center;

	> .o-grid__item {
		text-align: left;
	}
}

.o-grid--rev {
	direction: rtl;
	text-align: left;

	> .o-grid__item {
		direction: ltr;
		text-align: left;
	}
}