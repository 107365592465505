/* CONTROL.ROTATOR */

@use "sass:math";

.c-rotator {
}

.c-rotator__heading {
}

.c-rotator__viewport {
	padding-bottom: percentage(math.div(394, 1400));
}

.c-rotator__items {
}

.c-rotator__item {
	position: relative;
}

.c-rotator__body {
	position: absolute;
	left: $bsu;
	bottom: $bsu;
	padding: $bsu;
	color: $white;
	background: $brand;

	> *:last-child {
		margin-bottom: 0;
	}
}

//experience rotator
.c-experience-rotator {
	//arrow on desktop
	.slick-arrow {
		width: 35px;
		height: 60px;
		background: $brand;
        top: 49%;
		&:before {
			color: $body-color;
			font-size: 20px;
            display: block;
            margin-top: $bsu;

            @include bp("medium") {
                margin-top: 0;
            }

		}

		&.slick-disabled {
			opacity: 0;
		}

		@include bp('medium') {
			width: 50px;
			height: 100%;
			font-size: 30px;
			padding: 0;

			&:before {
				position: absolute;
				left: 50%;
				transform: translateX(-50%);
			}
		}
	}

	.slick-next {
		right: 0;
	}

	.slick-prev {
		left: 0;
	}
	//dot on mobile
	.slick-dots li button:before {
		color: $brand;
	}
}

.c-experience-rotator__items {
	@include bp('medium') {
		padding: 0 55px;
	}
}

.c-experience-rotator__item {
	padding: 0 $tsu
}