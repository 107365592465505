﻿.c-book-now {
	position: absolute;
	right:0;
	bottom: 0;
	width: 100px;
	@include bp('large'){
		width: auto;
	}
}

.c-book-now-btn {
    @include font-24-eb-flybook;
    background: $brand;
    border: none;
    padding-left: $bsu;
    padding-right: $bsu;
    padding-top: $bsu;
    padding-bottom: ($bsu - 5);
    border-radius: 0;

    @include bp('medium') {
        padding-bottom: ($bsu - 4);
        padding-top: ($bsu - 4);
    }

    @include bp('large') {
        border-radius: 2px;
    }
}

// c-book-now__flyout

.c-book-now__flyout {
    overflow-y: auto;
    overflow-x: hidden;
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    width: 100%;
    z-index: 1300;
    transform: translateX(101%);
    transition: all ease 0.3s;
    

    &.is-expanded {
        transform: translateX(0);
        transition: all ease 0.3s;
    }

}

.flyout__close_btn {
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 120;
    color: $black;

    &:focus,
    &:active {
        color: $black;
        outline: none;
    }

    @include bp('large') {
        top: 40px;
        right: 40px;
    }
}

.flyout__container {
    padding: 0;
    height: 100%;
}

.flyout__inner {
    display: flex;
    justify-content: center;
    align-items: stretch;
    height: 100%;
}

.flyout__inner_images {
    min-height: 250px;
    height: 100%;
    position: relative;
    z-index: 90;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    .is-expanded & {
        transform: translateX(0);
    }

    @include bp('medium') {
        transform: translateX(101%);
        transition: all ease 0.3s;
        transition-delay: 0.2s;
        height: 100vh;
    }
}


.flyout_image {
    display: none;

    @include bp('large') {
        display: none;
    }
}

.flyout__inner_content {
    height: 100%;
    margin-left: 0;
    position: relative;
    z-index: 100;
    background-color: $white;
    overflow: auto;

    @include bp('medium') {
        margin-left: -20px;
        height: 100vh;
    }
}

.flyout__inner_heading_wrapper {
    background-color: $brand;
    padding: 20px 40px 20px;

    @include bp('medium') {
        padding-top: 100px;
    }
}

.flyout__inner_heading {
    @include font-60-eb;
    text-transform: uppercase;
    color: #fff;
}

.flyout__inner_form_wrapper,
.flyout__inner_excerpt_wrapper {
    padding: 0 20px;

    @include bp('large') {
        padding: 0 40px;
    }
}

.flyout__inner_form_wrapper {
    width: 100%;
    margin: 20px 0;

    @include bp('large') {
        margin: 40px 0;
        max-width: 500px;
    }

    p{
        margin-bottom: 10px;
    }
}



.c-book-now-product-avail .form__label {
    @include font-20-eb;
    margin-bottom: 5px;
}

.c-book-now-product-avail .form__input,
.c-book-now-product-avail .form__select_box {
    @include font-20;
    display: block;
    width: 100%;
    height: 46px;
    line-height: 46px;
    padding: 0 20px;
    border-radius: 0;
    color: $body-color;
    margin-bottom: $bsu;
    border: 1px solid $grey;

    @include bp('large') {
        margin-bottom: 30px;
    }
}

.c-book-now-product-avail .type__promo-code {
    align-items: center;

    .form__label {
        margin-bottom: 5px;

        @include bp('large') {
            margin-bottom: 0;
        }
    }

    .form__input {
        margin-bottom: 0;
    }
}

.c-book-now-product-avail .js-check-avail-btn {
    background-color: $brand;
    border-color: $brand;
    margin-top: 20px;

    &:hover {
        background-color: $white;
        border-color: $black;
    }

    @include bp('large') {
        margin-top: 30px;
    }
}

.flyout__inner_excerpt_wrapper {
    background-color: $black;
}

.flyout_excerpt {
    padding: 20px 0 10px;
    color: $white;

    @include bp('large') {
        padding: 30px 0 20px;
    }
}

.flyout_excerpt__heading {
    @include font-30-eb;
    color: $brand;
    margin-bottom: 10px;
}

.flyout_excerpt__link,
.flyout_excerpt a{
    color: $white;
}

.exp_list {
    display: none;
    visibility: hidden;
}