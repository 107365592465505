﻿@use "sass:math";

.c-homepage-category-pathway {
	position: relative;
	background-size: cover;
	padding: $lsu 0;
	&:before {
		position: absolute;
		display: block;
		content: '';
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background: linear-gradient(to bottom, $blue--dark, rgba(0,0,0,0.1) 10%, rgba(0,0,0,0) 100%);
	}

	@include bp('large') {
		height: 0;
		overflow: hidden;
		padding-bottom: percentage(math.div(1080, 1920));
	}
}

.c-homepage-category-pathway__content {
	position: relative;
	@include bp('large') {
		width: 100%;
		left: 0;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
	}
}

.c-homepage-category-pathway__content-left, .c-homepage-category-pathway__content-right {
	@include bp('large') {
		width: 49%;
		display: inline-block;
		vertical-align: middle;
	}
}

.c-homepage-category-pathway__content-left {
	@include bp('extra-large') {
		padding-right: 60px;
	}
}

.c-homepage-category-pathway__content-right {
	@include bp('large') {
		padding-left: 60px;
	}

	@include bp('extra-large') {
		padding-left: 160px;
	}
}

.c-homepage-category-pathway__title {
	@include font-80-eb;
	color: $white;

	strong {
		display: block;
		color: $brand;
	}
}

.c-homepage-category-pathway__abstract {
	@include font-20;
	color: $white;
	margin-top: $bsu;
	@include bp('extra-large') {
		margin-top: $lsu;
	}
}

.c-homepage-category-pathway__tel {
	@include font-30-eb;
	color: $white;

	p {
		margin-bottom: 0;
	}
	a {
		color: $white;
		&:hover {
			color: $brand;
		}
	}
}

.c-homepage-category-pathway__actions {
	margin-top: $bsu;

	.c-homepage-category-pathway__btn {
		width: 100%;
		margin-bottom: $tsu;
	}

	@include bp('medium') {
		.c-homepage-category-pathway__btn {
			width: auto;
			padding-left: $bsu;
			padding-right: $bsu;
		}
	}

	@include bp('extra-large') {
		margin-top: $lsu;

		.c-homepage-category-pathway__btn:not(:first-of-type) {
			margin-left: $ssu;
		}
	}
}

.c-homepage-category-pathway__content-right {
	margin-top: $bsu;

	@include bp('large') {
		margin-top: 0;
		width: 50%;
	}
}

.c-homepage-category-pathway-list__items {
	width: 100%;
	@include ui-border('top')
}

.c-homepage-category-pathway-list__item {
	@include ui-border('bottom');
}

.c-homepage-category-pathway-list__item-link {
	@include font-30;
	display: flex;
	align-items: center;
	padding: $bsu;
	color: $white;

	&:hover{
		color: $brand;
	}

}
.c-homepage-category-pathway-list__item-icon {
	color: $brand;
	margin-right: $bsu;
}

.c-homepage-category-pathway__country-link {
	@include font-18-eb;
	display: block;
	color: $brand;
	margin-top: $bsu;
	text-align: right;
	.iconf-arrow-right{
		font-size: 14px;
	}
	&:hover{
		text-decoration: underline;
	}
}