/* TOOLS.MIXINS */

@use "sass:math";

// Breakpoint mixin
// Uses $breakpoints variable set in _config.scss file
@mixin bp($size) {

	@if (type-of($size) == "number") {

		@media (min-width: #{em($size)}) {
			@content;
		}
	}

	@else if (type-of($size) == "string") {

		@each $breakpoint in $breakpoints {

			// Get the name of the breakpoint.
			$alias: nth($breakpoint, 1);

			@if ($size == $alias) {
				$start: bpConfigValue($breakpoint, "start");

				@media (min-width: #{em($start)}) {
					@content;
				}
			}
		}
	}

	@else {
		@warn "Unfortunately, no value could be retrieved from '#{$size}'. Please make sure it is defined in '$breakpoints' map.";
	}
}

@mixin bp-below($size) {
	@if (type-of($size) == "number") {

		@media (min-width: #{em($size)}) {
			@content;
		}
	}

	@else if (type-of($size) == "string") {

		@each $breakpoint in $breakpoints {
			// Get the name of the breakpoint.
			$alias: nth($breakpoint, 1);

			@if ($size == $alias) {
				$start: bpConfigValue($breakpoint, "start");
				$end: bpConfigValue($breakpoint, "end");

				@media (max-width: #{em($end)}) {
					@content;
				}
			}
		}
	}

	@else {
		@warn "Unfortunately, no value could be retrieved from '#{$size}'. Please make sure it is defined in '$breakpoints' map.";
	}
}

// Transition
@mixin link-transition {
	transition: all 0.25s ease-in-out;
}

// Clearfix
@mixin clearfix {
	&:after {
		content: "";
		display: table;
		clear: both;
	}
}

// Pseudo Element
@mixin pseudo-element {
	content: '';
	display: block;
	position: absolute;
}

@mixin flex-extend {
	display: block;
	display: flex;
}

@mixin flex-wrap {
	flex-wrap: wrap;
}

// Placeholder Text
@mixin placeholder-text ($color) {
	&::-webkit-input-placeholder {
		color: $color;
	}
	&:-moz-placeholder {
		color: $color;
	}
	&:-ms-input-placeholder {
		color: $color;
	}
}

// Mixin to add square dimensions to an element i.e. the same width and height
// Example: Create a square 40em high and wide
// @include tb_square ( 40em );
@mixin square($size) {
	width: $size;
	height: $size;
}

// Mixin to add circle dimensions to an element i.e. adds square dimensions and border-radius of (dimensions / 2)
// Example: Create a circle 40em high and wide
// @include tb_square ( 40em );
@mixin circle($size) {
	@include square($size);
	border-radius: math.div($size, 2);
}

// UI Border mixin
$ui-border: 1px solid $base-ui-color;

@mixin ui-border($side) {
	@if ($side == 'all') {
		border: $ui-border
	} @else if ($side == 'top') {
		border-top: $ui-border;
	} @else if ($side == 'right') {
		border-right: $ui-border;
	} @else if ($side == 'bottom') {
		border-bottom: $ui-border;
	} @else if ($side == 'left') {
		border-left: $ui-border;
	}
}

@mixin input {
	// display: block;
	// width: 100% !important;
	// padding: $ssu $bsu;
	//border-radius: 2px;
	//font-weight: $weight--light;
	// color: $body-color;
	@include font-20;
	display: block;
	width: 100%;
	padding: 0 20px;
	border-radius: 0;
	color: $body-color;
	border: 1px solid $grey;

	//height: 53px;
	height: 46px;
	line-height: 46px;
}

