﻿/* BASE.TYPOGRAPHY */

@font-face {
	font-family: 'Mont-Heavy';
	src: url('../fonts/Mont/Mont-Heavy.eot');
	src: url('../fonts/Mont/Mont-Heavy.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Mont/Mont-Heavy.woff2') format('woff2'),
		url('../fonts/Mont/Mont-Heavy.woff') format('woff'),
		url('../fonts/Mont/Mont-Heavy.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}


// Config
//-------
// Base settings all set on the HTML element
// $base-font-size is used throughout to calculate em/rem sizes

$heading-font-family: "Mont-Heavy", Arial, Helvetica, sans-serif;
$base-font-family: 'Roboto', sans-serif;
$base-font-size: 16px;
$base-line-height: 22px;

$weight--light: 300;
$weight--normal: 400;
//$weight--semibold: 600;
$weight--bold: 700;
$weight--extra-bold: 800;

// Style declarations
/*
	.c-component__heading {
		@include font-12;
	}
*/

@mixin font-10 {
    font-size: 10px;
    line-height: 16px;
}

@mixin font-10-b {
    font-size: 10px;
    line-height: 16px;
    font-weight: $weight--bold;
}

@mixin font-12 {
	font-size: 12px;
	line-height: 18px;
}

@mixin font-12-eb {
	font-size: 12px;
	line-height: 18px;
	font-weight: $weight--extra-bold;
}

@mixin font-12-b-1 {
	font-size: 12px;
	line-height: 18px;
	font-weight: $weight--bold;
	text-transform: uppercase;
}

@mixin font-14 {
	font-size: 14px;
	line-height: 20px;
}


@mixin font-14-1 {
	font-size: 14px;
	line-height: 20px;
	text-transform: uppercase;
}

@mixin font-16 {
	font-size: 14px;
	line-height: lh(22, 16);

	@include bp('large') {
		font-size: 16px;
	}
}

@mixin font-16-b {
    font-size: 14px;
    line-height: lh(22, 16);
    font-weight: $weight--bold;

    @include bp('large') {
        font-size: 16px;
    }
}

@mixin font-16-eb {
	font-size: 14px;
	line-height: lh(22, 16);
	font-weight: $weight--extra-bold;

	@include bp('large') {
		font-size: 16px;
	}
}

@mixin font-18 {
	font-size: 15px;
	line-height: lh(24, 18);

	@include bp('large') {
		font-size: 18px;

	}
}

@mixin font-18-eb {
	font-size: 15px;
	line-height: lh(24, 18);
	font-weight: $weight--extra-bold;

	@include bp('large') {
		font-size: 18px;
	}
}

@mixin font-20 {
	font-size: 16px;
	line-height: lh(26, 20);

	@include bp('large') {
		font-size: 20px;
	}
}

@mixin font-20-eb {
	font-size: 16px;
	line-height: lh(26, 22);
	font-weight: $weight--extra-bold;

	@include bp('large') {
		font-size: 20px;
	}
}

@mixin font-20-eb-1 {
	font-size: 16px;
	line-height: lh(26, 22);
	font-weight: $weight--extra-bold;

	@include bp('extra-large') {
		font-size: 20px;
	}
}

@mixin font-20-eb-megamenu {
	font-size: 20px;
	line-height: lh(26, 22);
	font-weight: $weight--extra-bold;

	@include bp('large') {
		font-size: 20px;
	}
}

@mixin font-24-b {
    font-size: 18px;
    line-height: lh(30, 24);
    font-weight: $weight--bold;

    @include bp('large') {
        font-size: 20px;
    }

    @include bp('extra-large') {
        font-size: 24px;
    }
}

@mixin font-24-eb {
	font-size: 18px;
	line-height: lh(30, 24);
	font-weight: $weight--extra-bold;

	@include bp('large') {
		font-size: 20px;
	}

	@include bp('extra-large') {
		font-size: 24px;
	}
}

@mixin font-24-eb-flybook {
    font-size: 18px;
    line-height: 1;
    font-weight: $weight--extra-bold;

    @include bp('large') {
        font-size: 20px;
        line-height: lh(30, 24);
    }

    @include bp('extra-large') {
        font-size: 24px;
    }
}

@mixin font-24-eb-formCaption {
    font-size: 18px !important;
    line-height: lh(30, 24) !important;
    font-weight: $weight--extra-bold;


    @include bp('large') {
        font-size: 20px !important;
    }

    @include bp('extra-large') {
        font-size: 24px !important;
    }
}

@mixin font-30 {
	font-size: 20px;
	line-height: lh(36, 30);

	@include bp('large') {
		font-size: 30px;
	}
}

@mixin font-30-b {
	font-size: 20px;
	line-height: lh(36, 30);
	font-weight: $weight--bold;

	@include bp('large') {
		font-size: 30px;
	}
}


@mixin font-30-eb {
	font-size: 20px;
	line-height: lh(36, 30);
	font-weight: $weight--extra-bold;

	@include bp('large') {
		font-size: 30px;
	}
}


@mixin font-30-eb-1 {
	font-size: 20px;
	line-height: lh(36, 30);
	font-weight: $weight--extra-bold;
	text-transform: uppercase;

	@include bp('large') {
		font-size: 30px;
	}
}

@mixin font-40{
	font-size: 28px;
	line-height: lh(46, 40);

	@include bp('large') {
		font-size: 40px;
	}
}

@mixin font-40-eb {
	font-size: 28px;
	line-height: lh(46, 40);
	font-weight: $weight--extra-bold;

	@include bp('large') {
		font-size: 40px;
	}
}

@mixin font-60-eb {
    font-size: 36px;
    font-weight: $weight--bold;
    line-height: lh(66, 60);

    @include bp('large') {
        font-size: 60px;
    }
}

@mixin font-60-eb2 {
    font-size: 36px;
    font-weight: $weight--bold;
    line-height: lh(66, 60);

    @include bp('extra-large') {
        font-size: 60px;
    }
}

@mixin font-80-eb {
	font-size: 50px;
	font-weight: $weight--bold;
	line-height: 1;
	text-transform: uppercase;

	@include bp('large') {
		font-size: 60px;
	}

	@include bp('extra-large') {
		font-size: 80px;
	}
}

@mixin font-140-eb {
	font-size: 40px;
	font-weight: $weight--bold;
	line-height: 0.8;
	text-transform: uppercase;

	@include bp('medium') {
		font-size: 65px;
	}

	@include bp('large') {
		font-size: 90px;
	}

	@include bp('extra-large') {
		font-size: 110px;
	}
}

html {
	font-family: $base-font-family;
	color: $body-color;

	h1, h2, h3, h4, h5 {
		font-family: $heading-font-family;
		font-weight: $weight--normal;
	}
}

	p {
		margin-top: 0;
	}