﻿/* MACRO.BLOCKQUOTE */

.m-quote {
	@include bp('medium') {
		position: relative;
		padding-left: $lsu;
	}
}

.m-quote__text {
	@include font-20;
	margin-bottom: $bsu;
}

.m-quote__citation {
	@include font-20-eb;
	a {
		color: $body-color;

		&:hover {
			text-decoration: underline;
		}
	}
}

.m-quote-icon {
	display: block;
	margin-bottom: 5px;

	@include bp('medium') {
		position: absolute;
		left: 0;
		top: 5px;
		font-size: 22px;
	}
}
