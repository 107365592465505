/* COMPONENT.HEADER */

@use "sass:math";

$header-mobile-height: 64px;
$header-large-height: 88px;
$header-extra-large-height: 93px;
.c-header {
	position: fixed;
	z-index: $z-header;
	background-color: $grey--dark;
	transition: all 0.3s ease-in-out;
	width: 100%;
	-webkit-transform: translateZ(0);

	&.is-sticky {
		position: fixed;
		left: 0;
		top: 0;
	}

	.filter-open & {
		z-index: 0;
	}

	@include bp('large') {
		background-color: transparent;

		&.is-sticky, &.menu-expanded, &.lang-expanded {
			background-color: $grey--dark;
		}

		.filter-open & {
			z-index: $z-header;
		}
	}
}

.c-header__wrapper {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-top: $bsu;
	padding-bottom: $bsu;
	padding-right: 100px;
	height: $header-mobile-height;

	@include bp('large') {
		height: auto;
		padding-bottom: 0;
		padding-right: 140px;
		transition: all 0.3s ease-in-out;

		.is-sticky & {
			padding-top: 0;
		}
	}

	@include bp('extra-large') {
		height: auto;
		padding-bottom: 0;
		padding-right: 160px;
	}

	@include bp(1730px) {
		padding-right: 20px;
	}
}

.c-header__logo {
	display: inline-block;
	.c-header__logo-img__short {
		width: 30px;
		height: 100%;

		@include bp('extra-large') {
			display: none;

			.is-sticky & {
				display: block;
			}
		}
	}

	.c-header__logo-img__long {
		display: none;

		@include bp('extra-large') {
			display: block;
			width: 200px;
			height: 100%;
			margin-right: 20px;
			padding-bottom: 10px;

			.is-sticky & {
				display: none;
				padding-bottom: 0;
			}
		}
	}

	@include bp(1800px) {
		margin-right: 200px;

		.is-sticky & {
			margin-right: 300px;
		}
	}
}

.c-header__tools {
	@include flex-extend;
	align-items: center;
	justify-content: flex-end;
	flex: 1 0 auto;
}

.c-header__language-desktop {
	display: none;
	padding-left: $bsu;
	margin-left: $bsu;

	.selectric {
		background: none;
		overflow: hidden;
		white-space: nowrap;
		padding-right: 30px;
		cursor: pointer;
		border: none;
		text-transform: uppercase;

		.label {
			@include font-12;
			margin: 0;
			color: $white;
			height: auto;
		}

		.button {
			display: none;
		}

		&:after {
			@extend %iconf;
			content: "\e911"; //down arrow
			position: absolute;
			right: 10px;
			top: 50%;
			transform: translateY(-50%);
			font-size: 10px;
			color: $white;
			pointer-events: none;
		}
	}

	.selectric-items {
		background: $grey--dark;
		border: none;
		width: calc(100% + 30px) !important;
		right: 0;
		left: auto;
		min-width: 100px;

		li {
			color: $white;

			&.highlighted, &.selected, &:hover {
				background: none;
				color: $brand;
			}
		}
	}

	.c-header__language-icon {
		align-self: center;
		color: $white;
		margin-right: $ssu;
	}

	@include bp('large') {
		/*border-bottom: 1px solid $grey--mid;*/
		display: flex;
		height: 56px;
		opacity: 0;
		@include ui-border('left');

		.selectric-wrapper {
			align-self: center;
			padding: 20px 0;
		}

		&.is-loaded {
			opacity: 1;
		}
	}

	@include bp('extra-large') {
		margin-left: $lsu;
		padding-left: $lsu;
	}
}


// Mobile buttons

$mobile-button-size: 45px;
$mobile-button-color: $white;
$mobile-button--hover-color: $brand;
$mobile-button-font-size: 25px;
$mobile-button-padding: math.div(($mobile-button-size - $mobile-button-font-size), 2);

.c-header__mobile-buttons {
	flex: 1 0 auto;
	direction: rtl;

	@include bp('medium') {
		flex: 0 0 auto;
		order: 3;
	}

	@include bp('large') {
		display: none;
	}
}

.c-header__mobile-button {
	position: relative;
	width: $mobile-button-size;
	height: $mobile-button-size;
	padding: $mobile-button-padding 0;
	border: none;
	background: none;
	outline: none;
	transition: all 0.3s ease-in-out;
}

// Nav Toggle
$nav-toggle-border-width: math.div($mobile-button-font-size, 5);

.c-header__nav-toggle {

	b {
		transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out, border-color 0.3s ease-in-out;
		position: absolute;
		left: $mobile-button-padding;
		display: block;
		width: $mobile-button-font-size;
		height: 0;
		border-top: $nav-toggle-border-width solid $mobile-button-color;

		&:nth-child(1) {
			top: $mobile-button-padding;
		}

		&:nth-child(2) {
			top: $mobile-button-padding + ($nav-toggle-border-width * 2);
		}

		&:nth-child(3) {
			top: $mobile-button-padding + ($nav-toggle-border-width * 4);
		}
	}

	&.is-expanded {

		b {
			&:nth-child(1) {
				transform: translateY(10px) rotate(45deg);
			}

			&:nth-child(2) {
				opacity: 0;
			}

			&:nth-child(3) {
				transform: translateY(-10px) rotate(-45deg);
			}
		}
	}

	&:hover,
	&.is-expanded {
		b {
			border-color: $mobile-button--hover-color;
		}
	}
}

// Search Toggle
.c-header__search-toggle {
	font-size: $mobile-button-font-size;
	color: $mobile-button-color;
	cursor: pointer;
	padding: $ssu;

	&:hover,
	&.is-expanded {
		color: $mobile-button--hover-color;
	}

	@include bp('large') {
		font-size: 18px;
	}
}
.c-header__search {
	display: block;
	position: fixed;
	top: $header-mobile-height;
	left: 0;
	width: 100%;
	transform: translateX(100%);
	transition: all 0.5s ease;
	background: $grey--dark;
	height: calc(100vh - #{$header-mobile-height});

	&.is-expanded {
		transform: translateX(0);
		box-shadow: 0 8px 6px -6px rgba(0,0,0,0.2);
	}

	@include bp('large') {
		position: fixed;
		display: block;
		max-height: 0;
		overflow: hidden;
		transition: max-height 0.5s ease-in;
		height: auto;
		width: 100%;
		left: 0;
		top: $header-large-height;

		&.is-expanded {
			max-height: 200px;
			top: $header-large-height;
			height: auto;
			width: 100%;
		}

		.is-sticky & {
			top: 56px;

			.is-expanded {
				top: 56px;
			}
		}
	}

	@include bp('extra-large') {
		top: $header-extra-large-height;

		&.is-expanded {
			top: $header-extra-large-height;
		}

		.is-sticky & {
			top: 61px;

			.is-expanded {
				top: 61px;
			}
		}
	}
}

.c-header__search-wrapper {
	width: 65%;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	padding-bottom: 20px;

	@include bp('large') {
		width: 700px;
		position: static;
		padding: 50px 0 80px;
		margin: 0 auto;
		top: auto;
		left: auto;
		transform: none;
	}
}

.c-header__search-field {
	position: relative;
}

.c-header__search-input {
	display: block;
	padding: 0.3em 0.5em;
	width: 100%;
	margin: 0 auto;
	border: none;
	@include ui-border('bottom');
	border-radius: 0;
	text-align: center;
	outline: none;
	background: transparent;
	font-size: 22px;
	color: $white;
}

.c-header__search-btn {
	position: absolute;
	padding: 0;
	width: 50px;
	height: 50px;
	border: none;
	background: 0 0;
	outline: 0;
	right: -7px;
	top: 7px;
	margin-top: -13px;
	font-size: 26px;
	line-height: 1;
	color: $brand;
	cursor: pointer;

	&:hover {
		color: darken($brand, 10%);
	}
}

.c-header__language-toggle {
	font-size: $mobile-button-font-size;
	padding: $ssu;
	color: $mobile-button-color;
	cursor: pointer;

	&:hover,
	&.is-expanded {
		color: $mobile-button--hover-color;
	}
}

.c-header__language {
	@include ui-border('top');
	position: fixed;
	top: $header-mobile-height;
	left: 0;
	display: block;
	height: calc(100vh - #{$header-mobile-height});
	width: 100%;
	padding: $bsu;
	background: $grey--dark;
	transform: translateX(100%);
	transition: all 0.5s ease;

	&.is-expanded {
		top: $header-mobile-height;
		transform: translateX(0);
		overflow-y: auto;
	}

	@include bp('large') {
		@include ui-border('left');
		position: static;
		flex: 1 0 200px;
		height: auto;
		padding: 16px 0 16px 40px;
		margin-left: 40px;
		border-top: none;
		background: transparent;
		transform: none;
	}
}