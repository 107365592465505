﻿/* CONTROL.CTA */

.c-filter {
	position: fixed;
	top: 0;
	left: 0;
	transform: translateX(100%);
	transition: all 0.5s ease;
	width: 100%;
	background: $grey--dark;
	height: 100vh;
	z-index: 6;
	color: $white;

	&.is-open {
		transform: translateX(0);
		overflow-y: auto;
	}

	@include bp('large') {
		position: static;
		width: auto;
		transform: none;
		height: auto;
		background: none;
	}
}
.c-filter__legend {
	display: block;
	@include ui-border('bottom');
	@include font-30-eb;
	padding-bottom: $ssu;
	margin-bottom: $ssu;
	@include bp('large'){
		border: none;
	}
}

.c-filter__icon {
	display: none;
	@include square(45px);
	position: absolute;
	left: 0;
	top: 0;
	background: $grey--dark;
	color: $brand;

	&:before {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	@include bp('large') {
		display: block;
	}
}
.c-filter__form {
	padding: $lsu $bsu;

	@include bp('large') {
		padding: 0;
	}
}

.c-filter__fields {
	@include bp('large') {
		position: relative;
		padding-left: 45px;
	}
}

.c-filter__field {
	margin-bottom: $ssu;

	.selectric {
		background: $grey--light;
		overflow: hidden;
		white-space: nowrap;
		padding: $ssu 35px $ssu $ssu;
		cursor: pointer;
		border: 1px solid $grey;
		margin-bottom: $ssu;
		/*line-height: 1.15;*/
		.label {
			@include font-18-eb;
			margin: 0;
			color: $body-color;
			height: auto;
		}

		.button {
			display: none;
		}

		&:after {
			@extend %iconf;
			content: "\e911"; //down arrow
			position: absolute;
			right: 10px;
			top: 50%;
			transform: translateY(-50%);
			font-size: 14px;
			color: $body-color;
			pointer-events: none;
		}
	}

	.selectric-items {
		background: $grey--light;
		border: none;
		width: 100%;
		padding-top: $ssu;
		padding-bottom: $ssu;

		li {
			@include font-20-eb;
			color: $body-color;
			padding: $tsu $bsu;

			&.highlighted, &.selected, &:hover {
				background: none;
				color: $blue;
			}
		}
	}

	@include bp('large') {
		margin-bottom: 0;
		width: 25%;
		display: inline-block;
		float: left;
	}
}

.c-filter__label {
	display: block;
	@include font-24-eb;
	margin-bottom: $tsu;

	@include bp('large') {
		display: none;
	}
}

.c-filter__controls {
	display: flex;
	justify-content:space-around;
	margin-top: $bsu;
}

.c-filter-toggle__btn {
	width: 100%;

	@include bp('large') {
		width: auto;
		margin-bottom: 0;
	}
}

//filter summary
.c-filter-summary {
	margin-top: $bsu;

	@include bp('large') {
		margin-top: $ssu;
	}
}

.c-filter-summary__remove {
	@include font-16;
	color: $blue;
	margin-left: $bsu;
	cursor: pointer;
}

.c-filter-summary__icon{
	font-size: 14px;
}