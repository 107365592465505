.c-hero-rotator {
	position: relative;
	// Don't let it go over the top of the page, which contains the floating share icons
	z-index: 1;

	&:after {
		content: '';
		position: absolute;
		left: 0;
		bottom: 0;
		top: 0;
		right: 0;
		width: 100%;
		height: 100%;
		display: block;
		background: linear-gradient(to bottom, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.1) 30%, rgba(0, 0, 0, 0.05) 85%, rgba(0, 0, 0, 0.1) 100%);
	}
}

.c-hero-rotator__item {
	position: relative;

	&:nth-child(n+2) {
		display: none;
	}
}

.c-hero-rotator__image {
	display: block;
	width: 100%;
	max-width: 100%;
}

.c-hero-rotator__body {
	position: absolute;
	top: 0;
	left: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 100%;
	height: 100%;
	padding: 20px 10px;

	@include bp('large') {
		padding-bottom: 40px;
	}
}

.c-hero-rotator__heading {
	@include font-80-eb;
	color: $white;

	strong {
		display: block;
		color: $brand;
	}
}

.c-hero-rotator__subheading {
	@include font-30-eb;
	color: $white;
}

.c-hero-rotator__items.slick-initialized {
	.c-hero-rotator__item {
		&:nth-child(n+2) {
			display: block;
		}
	}

	.slick-arrow {
		top: auto;
		bottom: 40px;
		background: $brand;
		box-shadow: inset 0 0 0 1px $grey--dark;
		color: $grey--dark;

		@include flex-extend;
		align-items: center;
		justify-content: center;
		width: 40px;
		height: 40px;
		font-size: 0;

		&::before {
			display: block;
			font-size: 20px;
			color: inherit;
		}

		@include link-transition;

		&:hover,
		&:focus {
			background: $white;
			box-shadow: inset 0 0 0 1px $white;
		}
	}
}
