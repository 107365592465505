/* COMPONENT.MEGAMENU */

$megamemu__intro-width: 400px;
$megamenu-max-width: 1380px;

.c-megamenu {
	interpolate-size: allow-keywords;
	display: none;
	background: $grey--dark;
	color: $white;

	&, * {
		box-sizing: border-box;
	}

	&.is-expanded,
	.is-expanded > & {
		display: block;
	}

	&.is-expanded {
		box-shadow: 0 8px 6px -6px rgba($black, 0.2);
	}

	@include bp('large') {
		transition: height 0.5s ease-in-out;
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		display: block;
		height: 0;
		overflow: hidden;
		text-align: left;
		box-shadow: 0 8px 6px -6px rgba($black, 0.2);
		width: 100%;

		.c-navigation__item--one & {
			width: calc($megamenu-max-width * 0.175);
		}

		.c-navigation__item--two & {
			max-width: calc($megamenu-max-width * 0.5);
		}

		.c-navigation__item--three & {
			max-width: calc($megamenu-max-width * 0.77);
		}

		.c-navigation__item--four & {
			max-width: $megamenu-max-width;
		}

		&.is-expanded,
		.is-expanded > & {
			height: auto;
		}

		.no-js .c-navigation__item:hover & {
			display: block;
		}
	}
}

.c-megamenu__wrapper {
	padding-bottom: $ssu;

	@include bp('large') {
		padding: $bsu * 2 70px $bsu * 2 70px;
		width: 100%;

		&:has(.c-megamenu-view-all) {
			padding-bottom: $bsu;
		}

		.c-navigation__item--one & {
			padding-inline: 50px;
		}

		&:has(.c-promo) {
			display: grid;
			column-gap: 50px;
		}

		&:has(.c-promo--single) {

			.c-navigation__item--two & {
				grid-template-columns: 1fr 1fr;
			}

			.c-navigation__item--three & {
				grid-template-columns: 2fr 1fr;
			}

			.c-navigation__item--four & {
				grid-template-columns: 3fr 1fr;
			}
		}

		&:has(.c-promo--double) {

			.c-navigation__item--three & {
				grid-template-columns: 1fr 2fr;
			}

			.c-navigation__item--four & {
				grid-template-columns: 1fr 1fr;
			}
		}
	}
}


//experience

.c-megamenu-experience__heading {
	@include font-18;
	font-weight: $weight--bold;
	margin-bottom: $ssu;
	color: $brand;

	> a {
		color: inherit;
	}

	@include bp('large') {
		margin-bottom: $bsu;
	}
}

.c-megamenu-experiences-grouped { //grouped
	padding: 20px 20px 0 20px;

	/*.c-megamenu__wrapper:has(.c-promo) & { //different width when there is promo
		flex-wrap: wrap;
		width: 100%;
		max-width: calc($megamenu-max-width / 2);
		//flex-grow: 1;
	}*/

	@include bp('large') {
		width: 100%;
		display: grid;
		gap: 50px;
		grid-template-columns: repeat(auto-fill, minmax(264px, 1fr));
		padding-left: 0;
		padding-right: 0;
	}
}

.c-megamenu-experience { //grouped item
	width: 100%;
	margin-bottom: $bsu;

	&:last-of-type {
		margin-bottom: 0;
	}

	@include bp('large') {
		margin-bottom: 0;
	}
}

.c-megamenu-experiences { //not grouped
	padding: 15px 20px;

	@include bp('large') {
		flex-wrap: wrap;
		padding: 0;

		.c-navigation__item--two &,
		.c-navigation__item--three &,
		.c-navigation__item--four & {
			width: 100%;
			display: flex;
			flex-wrap: nowrap;
			padding-left: 0;
			padding-right: 0;
		}
	}
}



.c-megamenu-experience-item { // ungrouped item
	padding: 10px 0 0 0;

	@include bp('large') {
		//padding: 0;
		padding: 0 0 10px 0;

		&:last-child {
			padding-bottom: 0;
		}

		.c-navigation__item--two &,
		.c-navigation__item--three &,
		.c-navigation__item--four & {
			/*width: calc($megamenu-max-width / 4);*/
			width: 100%;
		}
	}
}

.c-megamenu-experience-item__link {
	padding-bottom: 10px;
	display: block;

	@include bp('large') {

		.c-megamenu-experience-item--grouped & {
			padding-bottom: 0;
		}
	}
}

.c-megamenu-experience-item__title {
	@include link-transition;
	@include font-16;
	color: $white;
	word-wrap: break-word;

	&:hover {
		color: $brand;
	}
}


// PROMO TILES
.c-promo {
	padding: 15px 20px;
	display: flex;
	gap: 40px;
}

.c-promo-item {
	padding: 0;
	flex: 1 1 50%;

	&:hover,
	&:focus {
		.c-promo-item__title {
			color: $brand;
		}
	}
}

.c-promo-item__link {
}

.c-promo-item__figure {
	position: relative;
}

.c-promo-item__image {
	margin-bottom: 5px;
}

.c-promo-item__icon {
	@include link-transition;
	width: 30px;
	height: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	right: 0;
	bottom: 0;
	color: $body-color;
	background: $brand;
}

.c-promo-item__title {
	@include link-transition;
	color: $white;
}



//view all
.c-megamenu-view-all {
	@include font-14;
	padding: 15px 20px;

	@include bp('large') {
		//position: absolute;
		//bottom: $lsu;
		//left: $lsu;
		@include font-16;
		border-top: 1px #d4d4d4 solid;
		margin-top: 30px;
		padding-left: 0;
		padding-top: 30px;
	}
}

.c-megamenu-view-all__link {
	@include link-transition;
	display: inline-block;
	font-weight: $weight--bold;
	color: $white;
}

.c-megamenu-view-all__icon {
	vertical-align: middle;
	margin-left: 10px;
}
