.c-popup {
	.c-modal__body {
		border-radius: 0;
		background-size: cover;
		background-position: center;
		padding:0;
	}
}

.c-popup__close {
	background-color: $brand;
	color: $body-color;
	border-radius: 50%;
	font-size: $ssu;
	top: $ssu;
	right: $ssu;
}

.c-popup__body-wrap {
	background: $white;
	padding: $lsu;

	@include bp('large') {
		max-width: 400px;
	}

	.c-popup--background & {


		@include bp('large') {
			margin-right: 300px;
			box-shadow: rgba($black, .25) 0 0 15px;
		}
	}
}

.c-popup__body {
	width: 100%;
	&[aria-hidden=true] {
		display: none;
	}
}

.c-popup__logo-img {
	display: block;
	margin-bottom: $bsu;
	max-width: 150px;

	@include bp('large') {
		margin-bottom: $lsu;
	}
}

.c-popup__heading {
	@include font-18-eb;
	//color: $white;
	margin: 0 0 $bsu;

	@include bp('large') {
		margin-bottom: ($bsu * 1.5);
	}

	&::after {
		content: "";
		display: block;
		width: 40px;
		height: 5px;
		background-color: $brand;
		margin-top: $ssu;
	}
}
.c-popup__text {
	//@extend .o-body-text;
	//color: $white;
	margin: 0 0 $bsu;

	&.o-body-text {
		@include bp('large') {
			@include font-16;
		}
	}
}

.c-popup__cta {
	@include button;
	@include font-16-eb;
	background-color: $brand;
	border: none;
	padding: ($ssu * 1.5) $bsu !important;
}

.c-popup__promo {
	@include font-18-eb;
	display: block;
	padding: $ssu $bsu;
	border-radius: 2px;
	text-align: center;
	background: $brand;
}

.c-popup__embed {
	h1 {
		display: none;
	}

	form[action^="https://www.createsend.com"] {
		display: grid;
		gap: $bsu;
		grid-template-columns: repeat(2, minmax(0, 1fr));
		margin-bottom: $bsu;

		> div {
			grid-column: 1 / 3;
		}

		> div > div {
			margin: 0 0 $ssu;
		}

		label {
			@include font-16-eb;
			display: block;
			width: 100%;
			padding: 5px 0;
			margin: 0 0 $tsu;
			color: $body-color;
		}

		input[aria-label] {
			width: 100%;
			padding: 5px;
			border: 1px solid $body-color;
			border-radius: 2px;
		}

		button[type=submit] {
			@include button;
			@include font-16-eb;
			background-color: $brand;
			border: none;
			padding: ($ssu * 1.5) $bsu !important;
		}
	}
}

.c-popup__close-btn {
	@include button;
	@include font-16-eb;
	background-color: $brand;
	border: none;
	padding: ($ssu * 1.5) $bsu !important;
}

.c-popup__disclaimer {
	@include font-10;
	margin-top: $lsu;
	margin-bottom: 0;
}