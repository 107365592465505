/* OBJECT.BTN */

// Structural base for all buttons. No cosmetic styles (colours, font sizes etc.) applied here

// .o-btn example usage
//-------------------
/*
	<a href="#" class="o-btn">Click here</a> or
	<a href="#" class="o-btn o-btn--full">Click here</a> or
	<button class="o-btn-reset">Click here</a>
*/
.o-btn--reset {
	padding: 0;
	border: none;
	background: none;
}

.o-btn {
	@include font-18-eb;
	@include link-transition;
	display: inline-block;
	padding: $ssu $bsu;
	border: 1px solid $body-color;
	border-radius: 2px;
	text-align: center;
	text-decoration: none;
	vertical-align: middle;
	cursor: pointer;
	color: $body-color;

	@include bp('large') {
		padding: 16px $lsu;
	}

	&:hover {
		border-color: transparent;
		background: $brand;
	}
}

.o-btn--on-yellow {
	&:hover {
		border-color: transparent;
		background: $white;
	}
}

.o-btn-on-image {
	color: $brand;
	border-color: $brand;
	background: rgba(0, 0, 0, 0.15);

	&:hover {
		color: $body-color;
	}
}

.o-btn--full {
	display: block;
	width: 100%;
}

@mixin button {
	@include font-18-eb;
	@include link-transition;
	display: inline-block;
	padding: $ssu $bsu;
	border: 1px solid $body-color;
	border-radius: 2px;
	text-align: center;
	text-decoration: none;
	vertical-align: middle;
	cursor: pointer;
	color: $body-color;

	@include bp('large') {
		padding: 16px $lsu;
	}

	&:hover {
		border-color: transparent;
		background: $brand;
	}
}