@use "sass:math";

.c-modal {
    display: none;

    &[aria-hidden="false"] {
        @include flex-extend;
        align-items: center;
        justify-content: center;
    }

    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba($black, 0.6);
}

.c-modal--full {
    .c-modal__body {
        max-width: none;
        max-height: none;
        width: 80%;
        height: 80%;
        // 45px is the close button height plus its 1px offset
        padding: #{45px + $bsu} 0 $lsu;

        @include bp("medium") {
            padding: $hsu 0;
        }

        box-shadow: none;
    }

    .c-modal__close {
        right: 20px;

        @include bp($layout-wrapper-max-width) {
            right: 50%;
            transform: translateX(#{math.div($layout-wrapper-max-width, 2) - 20px});
        }
    }

    background: $white;
}

.c-modal__body-open {
    // To be placed on the <body> tag when a modal is open
    // Uncomment this rule to prevent background scrolling
    // while a modal window is open:
    // overflow: hidden;
}

.c-modal__body-open--full {
    overflow: hidden;
}

.c-modal__body {
    position: relative;
    overflow: auto;
    padding: 44px;
    width: auto;
    max-width: 500px;
    height: auto;
    max-height: 80%;

    @include bp("medium") {
        width: auto;
        max-width: 500px;
        height: auto;
        max-height: 80%;
        padding: $lsu $hsu;
    }

    @include bp("large") {
        max-width: 80%;
    }

    background: $white;
    box-shadow: 0 0 20px rgba($black, 0.4);
}

.c-modal__close {
    @extend .o-btn--reset;
    position: absolute;
    // Offset by 1px so focus style isn't cut off
    top: 1px;
    right: 1px;
    display: block;
    padding: 10px;
    font-size: 24px;
    line-height: 1;
    color: $brand;
    text-shadow: rgba($black, .35) 0 0 1px;
    cursor: pointer;

    &:hover,
    &:focus {
        color: $black;
    }

    &:before {
        font-family: 'font-icons';
        content: $iconf-close;
    }
}

.c-modal__dummy {
    position: absolute;
    clip: rect(0 0 0 0);
    overflow: hidden;
    width: 1px;
    height: 1px;
    padding: 0;
    border: 0;
    margin: -1px;
}
