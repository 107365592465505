﻿/* OBJECT.ICON-WRAPPER */

.o-icon-wrapper {
	position: relative;
	display: block;

	&:before {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
	}
}