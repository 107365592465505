/* OBJECT.EMBED */

@use "sass:math";

.o-embed {
	position: relative;
	overflow: hidden;
	height: 0;

	.o-embed__content,
	iframe,
	object,
	embed {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border: 0;
	}
}

.o-embed--widescreen {
	padding-bottom: percentage(math.div(9, 16));
}