﻿/* CONTROL.MEMBER-LOGIN */

.c-member-login {
	width: 100%;
	max-width: 400px;
	margin-right: auto;
	margin-left: auto;
    padding: 0 $bsu;

    @include bp('medium'){
        padding: 0;
    }

}

.c-member-login__heading {
}

.c-member-login__form {
    padding: $lsu;
    border: 1px solid $grey--light;
    background: $grey--light;
}

.c-member-login__form-field {
	margin-bottom: $ssu;
}

.c-member-login__label {
    @include font-20-eb;
    display: block;
    margin-bottom: 5px;
}

.c-member-login__input {
    @include font-20;
    display: block;
    width: 100%;
    padding: 0 20px;
    border-radius: 0;
    color: $body-color;
    height: 45px;
    line-height: 45px;
    margin-bottom: $bsu;
    border: 1px solid $grey;

    @include bp('large') {
        margin-bottom: ($bsu + 10px);
    }
}

.c-member-login__error {
	padding: $ssu;
	border: 1px solid $error;
	margin-top: $bsu;
	color: $error;
	background: $error--light;

    &.validation-summary-valid {
        display: none;
    }
}