/* COMPONENT.FOOTER */

.c-footer {
	@include ui-border(top);
	position: relative;
	padding-bottom: $bsu;

	@include bp('large') {
		padding-bottom: $lsu;
	}

	@include bp('extra-large') {
		padding-bottom: $bsu*3;
	}
}

.c-footer__primary {
	&:first-child {
		padding-top: $bsu;

		@include bp('large') {
			padding-top: $lsu;
		}

		@include bp('extra-large') {
			padding-top: $bsu*3;
		}
	}
}

.c-footer-social {
	display: flex;
	align-items: flex-end;
	justify-content: center;
	width: 100%;
	margin-top: $lsu;

	@include bp('extra-large') {
		margin-top: 0;
	}
}

.c-footer-social__block {
	text-align: center;
	padding: 0 $ssu $ssu $ssu;

	& + & {
		border-left: 1px $grey--dark solid;
	}

	@include bp('medium') {
		margin-top: 0;
		padding: 0 $bsu $bsu $bsu;
	}
}

.c-footer-social__list-item {
	display: inline-block;
	margin-right: $tsu;
	margin-bottom: $tsu;
}

.c-footer-social__link {
	@include circle(50px);
	@include link-transition;
	font-size: 20px;
	background: $body-color;
	color: $white;

	&:hover {
		background: $brand;
		color: $body-color;
	}

	@include bp('large') {
		@include circle(60px);
	}
}

.c-footer-image {
	margin-bottom: $bsu;
	width: 100px;
	vertical-align: middle;

	& + & {
		margin-left: $bsu;
	}

	@include bp('large') {
		width: 120px;

		& + & {
			margin-left: $lsu;
		}
	}
}

.c-footer__links {
	margin-bottom: $bsu;

	@include bp('small') {
		column-count: 2;
		column-gap: 40px;
	}
}

.c-footer__link {
	@include font-16;
	color: $body-color;

	&:hover {
		text-decoration: underline;
	}
}

.c-footer-buttons {
	margin-top: $lsu;
	display: flex;
	width: 100%;
	margin-bottom: $ssu;
}

.c-footer-btn {
	padding: $ssu $bsu;

	& + & {
		margin-left: $ssu;
	}

	@include bp('large') {
		padding: $bsu $lsu;

	}
}

.c-footer-bottom {
	padding: $ssu 0;
	background: $grey--dark;

	@include bp('medium') {
		padding: $bsu 0;
	}
}

.c-footer-bottom-content {
	text-align: center;

	@include bp('medium') {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
}

.c-footer-bottom-content {
	@include font-12;
	color: $grey;
}

.c-footer-bottom-link {
	@include font-12;
	color: $grey;

	&:hover {
		text-decoration: underline;
	}
}

.c-footer-back-to-top {
	display: block;
	padding: $ssu 0;
	color: $grey--dark;

	&:hover {
		color: $brand;
	}

	@include bp-below('small') {
		z-index: $z-back-to-top__mobile;
		bottom: 30px;
		right: 10px;
		position: fixed;
		opacity: 0;
		visibility: hidden;
		width: 45px;
		height: 45px;
		background: $brand;
		transition: opacity visibility, 0.2s, ease-in-out;

		&:hover {
			color: $grey--dark;
		}

		&:before {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}

		&.fade-in {
			opacity: 1;
			visibility: visible;
		}

		.filter-open & {
			z-index: 0;
		}
	}
}

.c-footer__nav {
	text-align: left;
}

.c-footer__cta {
	background: $brand;
	padding: $bsu 0;
	margin-bottom: $bsu;
}

.c-footer__cta-layout {
	display: flex;
	align-items: center;
	flex-wrap: wrap;

	@include bp("medium") {
		flex-wrap: nowrap;
	}
}

.c-footer__cta-heading {
	@include font-20-eb;
	margin: $bsu 0;
	margin-right: $bsu;

	&:last-child {
		margin-right: 0;
	}
}

.c-footer__cta-button {
	white-space: nowrap;
}
