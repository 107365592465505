﻿.c-site-announcement {
    padding: 20px;
    background: #ff1c1c;

    p {
        color: #000;
        margin-bottom: 0;
        font-weight: bold;
    }

    a {
        text-decoration: underline;
        color: #000;
        transition:color #fff 0.2s ease-out;

        &:hover{
            color:#fff;
        }
    }
}
