/* COMPONENT.CAROUSEL */

.c-carousel__items {
	@include clearfix;
}

.c-carousel__item {
	width: 100%;

	&:nth-child(n+2) {
		display: none;
	}

	@include bp('small') {
		width: 50%;
		float: left;

		&:nth-child(n+2) {
			display: block;
		}

		&:nth-child(n+3) {
			display: none;
		}
	}

	@include bp('large') {
		width: 33.3333%;

		&:nth-child(n+3) {
			display: block;
		}

		&:nth-child(n+4) {
			display: none;
		}
	}

	.slick-initialized & {
		display: block !important;
	}
}