﻿/* MACRO.VIDEO */

@use "sass:math";

.m-video {
}

.m-video__embed {
	padding-bottom: percentage(math.div(9, 16));
}

.m-video__figcaption {
}
.m-video__cover {
	&:hover {
		.m-video__icon {
			opacity: 1;
		}
	}
}
.m-video__icon {
	@include circle(50px);
	@include link-transition;
	border: 2px solid $brand;
	opacity: 0.6;

	&:before {
		position: absolute;
		top: 50%;
		left: 16px;
		transform: translateY(-50%);
		font-size: 20px;
		color: $brand;
	}

	.is-playing & {
		display: none;
	}

	@include bp('medium') {
		@include circle(80px);

		&:before {
			left: 26px;
			font-size: 35px;
		}
	}

	@include bp('extra-large') {
		@include circle(100px);

		&:before {
			left: 32px;
			font-size: 45px;
		}
	}
}